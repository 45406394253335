import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbsWrapper: {
        marginBottom: '80px',
        borderBottom: '1px solid #ebebeb',
        marginLeft: '30px',
        padding: '0',
        color: '#b5b5b5',
        [theme.breakpoints.down('md')]: {
           marginLeft: '0',
        },
    },
    breadcrumbsLink: {
        color: '#6f97ff',
        fontFamily: "'Poppins', sans-serif",
        "&:hover": {
            color: '#0355d5',
        },
        textTransform: 'capitalize',
    },
}));

export { useStyles };