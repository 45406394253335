import React from 'react';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import doctorDemo from '../../assets/images/doctor-demo.png'

import { useStyles } from './HomeContentStyles.js'

const DepartmentsList = ({ listDepartments }) => {

    const classes = useStyles();

    const [departments, setDepartments] = useState(null);
    
    useEffect(() => {
        (async() => {
            const departments = await listDepartments();
            setDepartments(departments);
        })();
    }, [listDepartments]);

    if (!departments || departments.length === 0) return <p>Can not find any department, sorry</p>;
    return (
        <Grid container mt={2}>
        {departments.map((department) => {
            return(
                <Grid item key={department.id} xs={12} sm={12} md={6} className={classes.homeContentList}>
                    <Link to={'servizi/' + department.slug} component={NavLink} className={classes.homeDepartmentLink} title={department.name}> <strong>- {department.name}</strong></Link>
                </Grid>
            );                           
        })}
        </Grid> 
    );
};

function HomeContent({ listDepartments }) {    

    const classes = useStyles();
    
    return (
        <>
            <Box className={classes.homeContentWrapper} mt={30}>
                <Box className={classes.homepageOverlay}>
                    <Container maxWidth="xl">
                        <Grid container>
                            <Grid item sm={12} md={6} className={classes.homeContentCEOCard}>
                                <Paper elevation={2} className={classes.homeContentCEOPaper}>
                                    <Typography className={classes.homeContentCEOSubTitle}>
                                        UNO STILE DI VITA SANO CONTRIBUISCE A
                                    </Typography>
                                    <Typography variant="h4" className={classes.homeContentCEOTitle}>
                                        UNA BUONA SALUTE
                                    </Typography>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <img src={doctorDemo} className={classes.homeContentCEOImage} alt="CEO" />
                                        </Grid>
                                        <Grid item md={6} sx={{ padding: '10px', marginBottom: '15px' }}>
                                            <Typography mt={5} mb={5} className={classes.homeContentText}>
                                                <strong>Richiedi un appuntamento</strong> e <strong>prenota una visita</strong> on line da uno dei nostri specialisti direttamente da questo sito web.
                                            </Typography>
                                            <Button to="/prenota-una-visita" component={NavLink} color="inherit" variant="outlined" className={classes.homePrenotaSubitoButton} title="Prenota subito">Prenota subito</Button>                                       
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item sm={12} md={6} className={classes.homeContentCard}>
                                <Typography variant="h3" className={classes.homeContentTitle}>
                                    Elenco Servizi
                                </Typography>
                                <DepartmentsList listDepartments={listDepartments} />                         
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box className={classes.mapWrapper}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.1595331504545!2d14.010691415905898!3d42.6791628791668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133196a21fc78691%3A0x6fe3f18bdec761f0!2sStudio%20Medico%20Associato%20Ippocrates!5e0!3m2!1sit!2sit!4v1634312839863!5m2!1sit!2sit" width="100%" height="450" allowFullScreen="" loading="lazy" className={classes.iframe} title="Google Map"></iframe>
            </Box>
        </>
    );
}

export default HomeContent;