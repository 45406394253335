import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
        padding: '50px 30px',
        backgroundColor: '#f9f9f9',
    },
    loginTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
        fontWeight: '600',
    },
    submit: {
        padding: '20px',
    },
    loginErrors: {
        color: 'red', 
        textAlign: 'center',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        marginTop: '10px',
    },
    inputField: {
        backgroundColor: '#fff',
    },
    companyInfoTitle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
        },
    },
    companyInfoSubTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#6f97ff',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
}));

export { useStyles };