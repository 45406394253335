import React from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useStyles } from './AboutStyles.js'

function About({ company }) {

    const classes = useStyles();

    const pageInfo = [
        {
            title: 'Chi Siamo',
            subtitle: 'Ippocrates - Nucleo Medico Specialistico',
        },
    ];

    return(
        <>
        <PageHeader pageInfo={pageInfo} />
        <Box>
            <Container maxWidth="xl" className={classes.aboutContent}>
                <Box sx={{ padding: '30px' }}>
                    <img src={company.logo} width="120" alt={company.name} />
                    <Typography variant="h2" className={classes.aboutContentTitle}>
                        {company.name}
                    </Typography>
                    <Typography variant="h4" component="h3" mb={5} className={classes.aboutContentSubTitle}>
                        {company.slogan}
                    </Typography>
                    <Typography variant="body2" className={classes.aboutContentDescription}>
                        {company.description}
                    </Typography>
                </Box>
            </Container>
        </Box>
        </>
    );
}

export default About;