import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import itLocale from 'date-fns/locale/it';

import { useStyles } from './EditAppointmentStyles.js';


function EditAppointment({ listRooms, listDoctors, appointmentDetails, editAppointment }) {

    const classes = useStyles();

    const history = useHistory();

    const { appointmentID } = useParams();

    const [appointment, setAppointment] = useState();

    const [doctorList, setDoctorList] = useState(null);

    useEffect(() => {
        (async() => {
            const doctorList = await listDoctors();
            setDoctorList(doctorList);
        })();
    }, [listDoctors, appointmentID]);

    const [roomList, setRoomList] = useState(null);

    useEffect(() => {
        (async() => {
            const roomList = await listRooms();
            setRoomList(roomList);
        })();
    }, [listRooms, appointmentID]);

    const initialPostData = Object.freeze({
        appointment_date: new Date(),
        duration: '',
        room: '',
        patient_first_name: '',
        patient_last_name: '',
        patient_email: '',
        patient_phone: '',
        status: '',
        doctor: '',
        notes: '',
        send_confirm: 'off',
    });

    const [postData, updatePostData] = useState(initialPostData);
    
    useEffect(() => {
        (async() => {
            const appointment = await appointmentDetails(appointmentID);
            setAppointment(appointment);
            updatePostData({
                ...postData,
                'appointment_date': appointment.appointment_date || new Date(),
                'duration': appointment.duration,
                'room': appointment.room,
                'patient_first_name': appointment.patient_first_name,
                'patient_last_name': appointment.patient_last_name,
                'patient_email': appointment.patient_email,
                'patient_phone': appointment.patient_phone,
                'doctor': appointment.doctor,
                'doctor_first_name': appointment.doctorFirstName,
                'doctor_last_name': appointment.doctorLastName,
                'notes': appointment.notes || '',
                'status': appointment.status,
            });            
        })();
    }, [appointmentDetails, appointmentID]);

    const handleChange = (e, value) => {
        if(String([e.target.name]) === 'doctor'){
            updatePostData({   
                ...postData,
                [e.target.name]: e.target.value,
                'doctor_last_name': value.props.children[0],
                'doctor_first_name': value.props.children[2],
            });
        } else if(String([e.target.name]) === 'room'){    
            updatePostData({
                ...postData,
                [e.target.name]: e.target.value,
            });
        } else if(String([e.target.name]) === 'duration'){    
            updatePostData({
                ...postData,
                [e.target.name]: e.target.value,
            });
        } else if(String([e.target.name]) === 'send_confirm'){
            if(postData.send_confirm === 'off') {
                updatePostData({
                    ...postData,
                    'send_confirm': 'on',
                });
            } else {
                updatePostData({
                    ...postData,
                    'send_confirm': 'off',
                });
            }            
        } else {    
            updatePostData({
                ...postData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleDateChange = (date) => {
        updatePostData({
            ...postData,
            appointment_date: date,
        });
    }

    const [formResponse, setFormResponse] = useState(false);
    const [formError, setFormError] = useState({
        patient_first_name: false,
        patient_last_name: false,
        duration: false,
        room: false,
    });

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Check Errors
        if(postData.patient_first_name === '' || postData.patient_first_name === null) {
            setFormError({
                duration: false,
                room: false,
                patient_first_name: true,
                patient_last_name: false,
            });
            return;
        }
        if(postData.patient_last_name === '' || postData.patient_last_name === null) {
            setFormError({
                duration: false,
                room: false,
                patient_first_name: false,
                patient_last_name: true,
            });
            return;
        }
        if(postData.duration === '' || postData.duration === null) {
            setFormError({
                duration: true,
                room: false,
                patient_first_name: false,
                patient_last_name: false,
            });
            return;
        }
        if(postData.room === '' || postData.room === null) {
            setFormError({
                duration: false,
                room: true,
                patient_first_name: false,
                patient_last_name: false,
            });
            return;
        }
        setFormError(false);

        const submitForm = await editAppointment(appointmentID, postData);

        if(submitForm.success === 'Sent' || submitForm.success === 'Saved') {
            history.push({
                pathname: '/admin/elenco-appuntamenti/' + appointmentID + '?action=' + submitForm.success,
            });
            window.location.reload();
        } else {
            setFormResponse(submitForm.success);
        }
    };

    if(!appointment || appointment.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    if(!roomList || roomList.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    if(!doctorList || doctorList.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <>
        <Box>
            <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={10}>
                        <Typography className={classes.appointmentTitle}>
                            Gestisci Appuntamento
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} sx={{ textAlign: 'right' }}>
                        <Button variant="outlined" href={'/admin/elenco-appuntamenti/elimina/' + appointment.id} className={classes.deleteButton}>Elimina</Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container mt={3}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Typography className={classes.patientInfo}><strong>Data Richiesta:</strong> {appointment.dateFormat}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.appointmentWrapper}>
                    <Typography className={classes.formDataTitle}>
                        Dati paziente
                    </Typography>                  
                    <form className={classes.editForm}>
                        <Grid container spacing={2} mb={5}>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="patient_first_name"
                                    label="Nome"
                                    name="patient_first_name"
                                    value={postData.patient_first_name}
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    helperText={formError.patient_first_name && 'Il campo Nome Paziente non può essere vuoto'}   
                                    error={formError.patient_first_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="patient_last_name"
                                    label="Cognome"
                                    name="patient_last_name"
                                    value={postData.patient_last_name}
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    helperText={formError.patient_last_name && 'Il campo Cognome Paziente non può essere vuoto'}   
                                    error={formError.patient_last_name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    id="patient_email"
                                    label="Indirizzo Email"
                                    name="patient_email"
                                    value={postData.patient_email}
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    helperText={formError.patient_email && 'Indirizzo email non valido'}   
                                    error={formError.patient_email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    id="patient_phone"
                                    label="Telefono"
                                    name="patient_phone"
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    value={postData.patient_phone}
                                />
                            </Grid>
                        </Grid>

                        <Divider />
                
                        <Typography className={classes.formDataTitle} mt={4}>
                            Dati appuntamento
                        </Typography>                    
                        
                        <Grid container spacing={2} mt={1} mb={2}>
                            <Grid item xs={12} md={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                                    <DateTimePicker
                                        label="Data e Ora *"
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        name="appointment_date"
                                        value={postData.appointment_date}
                                    />
                                </LocalizationProvider>
                            </Grid>                            
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="duration-select-label" required >Durata</InputLabel>
                                    <Select
                                        required
                                        labelId="duration-select-label"
                                        id="duration-select"
                                        label="Durata"
                                        name="duration"
                                        onChange={handleChange}
                                        className={classes.inputField}
                                        value={postData.duration}
                                        error={formError.duration}
                                    >
                                        <MenuItem key="15" value="15">15 Minuti</MenuItem>
                                        <MenuItem key="20" value="20">20 Minuti</MenuItem>
                                        <MenuItem key="30" value="30">30 Minuti</MenuItem>
                                        <MenuItem key="40" value="40">40 Minuti</MenuItem>
                                    </Select>
                                </FormControl> 
                                {formError.duration && <FormHelperText className={classes.error}>Selezionare una durata</FormHelperText>}   
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="room-select-label" required >Stanza</InputLabel>
                                    <Select
                                        required
                                        labelId="room-select-label"
                                        id="room-select"
                                        label="Stanza"
                                        name="room"
                                        onChange={handleChange}
                                        className={classes.inputField}
                                        value={postData.room}
                                        error={formError.room}
                                    >
                                    {roomList.map((room) => {
                                        return(
                                            <MenuItem key={room.id} value={room.id} >{room.name}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </FormControl>
                                {formError.room && <FormHelperText className={classes.error}>Selezionare una stanza</FormHelperText>}   
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="status-select-label">Stato</InputLabel>
                                    <Select
                                        required
                                        labelId="status-select-label"
                                        id="status-select"
                                        label="Stato"
                                        name="status"
                                        onChange={handleChange}
                                        value={postData.status}
                                        className={classes.inputField}
                                    >                                
                                        <MenuItem value="waiting">In Attesa</MenuItem>
                                        <MenuItem value="confirmed">Confermato</MenuItem>
                                        <MenuItem value="closed">Chiuso</MenuItem>                                   
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="doctor-select-label" required>Specialista</InputLabel>
                                    <Select
                                        required
                                        labelId="doctor-select-label"
                                        id="doctor-select"
                                        label="Specialista"
                                        name="doctor"
                                        onChange={handleChange}
                                        value={postData.doctor}
                                        className={classes.inputField}
                                        error={formResponse && formResponse.doctor}
                                    >
                                    {doctorList.map((doctor) => {
                                        return(
                                            <MenuItem key={doctor.id} value={doctor.id}>{doctor.last_name} {doctor.first_name}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </FormControl>
                                {formResponse && formResponse.doctor && <FormHelperText className={classes.error}>Selezionare specialista</FormHelperText>}   
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="notes"
                                    name="notes"
                                    label="Note"
                                    multiline
                                    rows={10}
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    value={postData.notes}
                                />
                            </Grid>
                        </Grid>
                        {(appointment.status === 'waiting') &&
                            <Grid item xs={12} mb={5}>
                                <FormControlLabel
                                    control={<Checkbox value={postData.send_confirms} name="send_confirm" color="primary" onChange={handleChange} />}
                                    label="Invia email di conferma al paziente"
                                    className={classes.checkboxLabel}
                                />
                            </Grid>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            Salva
                        </Button>
                    </form>
                    {formResponse && (typeof formResponse === 'string') && formResponse.includes('UNIQUE constraint failed') &&
                        <Alert severity="error" className={classes.formResponse}>
                            <p>Esiste già un appuntamento per questa data</p>
                        </Alert>
                    }   
                </Box>
            </Container>
        </Box>
        </>
    );
}

export default EditAppointment;