import React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

import { useStyles } from './DepartmentsStyles.js';

const Departments = ({ listDepartments }) => {

    const [departments, setDepartments] = useState(null);
    
    useEffect(() => {
        (async() => {
            const departments = await listDepartments();
            setDepartments(departments);
        })();
    }, [listDepartments]);

    const classes = useStyles();
    
    if (!departments || departments.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return (
        <React.Fragment>
            <Container maxWidth="xl" component="main" className={classes.departmentsWrapper}>
                <Grid container spacing={5} alignItems="flex-end">
                    {departments.map((department) => {
                        return (
                            <Grid item key={department.id} xs={12} sm={6} md={3}>
                                <Card className={classes.card}>
                                    <Link
                                        component={NavLink}
                                        color="textPrimary"
                                        to={'servizi/' + department.slug}
                                        className={classes.link}
                                        title={department.name}
                                    >
                                        <CardMedia
                                            className={classes.cardMedia}
                                            image={department.image}
                                            title={department.name}
                                        />
                                    </Link>
                                    <CardContent className={classes.cardContent}>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.cardTitle}
                                        >
                                            <Link
                                                component={NavLink}
                                                color="textPrimary"
                                                to={'servizi/' + department.slug}
                                                className={classes.link}
                                                title={department.name}
                                            >
                                                {department.name}
                                            </Link>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Departments;