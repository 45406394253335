import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardMainWrapper: {
        marginTop: '80px',
    },
    appointmentTitle: {
        color: '#1e375f',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '10px',
    },
    formDataTitle: {
        color: '#1976d2',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.2rem',
        fontWeight: '600',
        marginBottom: '10px',
    },
    appointmentWrapper: {
        marginTop: '30px',
    },
    patientInfo: {
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
        marginTop: '10px',
    },
    checkboxLabel: {
        '& span': {
            fontFamily: "'Poppins', sans-serif !important",
        },
    },
    messageBox: {
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
        marginTop: '10px',
        whiteSpace: 'pre-line',
        padding: '10px',
        border: '1px solid #ebebeb',
        borderRadius: '5px',
    },
    editForm: {
        marginTop: '20px',
    },
    error: {
        color: '#d32f2f',
    },
    formResponse: {
        fontFamily: "'Poppins', sans-serif",
        marginTop: '20px',
    },
    deleteButton: {
        width: '100%',
        border: '1px solid #b71c1c',
        color: '#b71c1c',
        '&:hover': {
            backgroundColor: '#d50000',
            border: '1px solid #b71c1c',
            color: '#fff',
        }
    },
}));

export { useStyles };