import React from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Departments from '../Departments/Departments';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useStyles } from './ServicesStyles.js'

function Services({ listDepartments }) {

    const classes = useStyles();

     const pageInfo = [
        {
            title: 'Servizi',
            subtitle: 'Si effettuano visite mediche in numerose branche specialistiche',
        },
    ]; 

    return(
        <>
            <PageHeader pageInfo={pageInfo} />
            <Box>
                <Container maxWidth="xl" className={classes.departmentsWrapper}>
                    <Departments listDepartments={listDepartments} />
                </Container>
            </Box>
        </>
    );
}

export default Services;