import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { NavLink } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import ActaLogo from '../../assets/images/logo_actainfo.png';

import { useStyles } from './FooterStyles.js'

function Footer({ company, footerLinks }) {

    const classes = useStyles();

    return(  
        <React.Fragment>
            <CookieConsent buttonText="Accetta" style={{ background: "#28324c", fontFamily: "'Poppins', sans-serif" }} buttonStyle={{ background: "#3c70fa", color: "#fff" }}>Questo sito web utilizza i cookie per migliorare la vostra esperienza di navigazione. <Link to="/privacy-policy" component={NavLink} className={classes.cookieLink}>Informativa Privacy</Link></CookieConsent>
            <Box className={classes.footerWrapper}>
                <Container maxWidth="xl" component="footer" className={classes.footer}>
                    <Grid container spacing={4} justifyContent="space-evenly">
                        <Grid item key="companyFooter1" md={6}>
                            <Grid container>
                                <Grid item key="companyLogo" md={2}> 
                                    <img src={company.logo_alt} width="80" alt={company.name} />
                                </Grid>
                                <Grid item key="companyTitles" md={10}>
                                    <Typography component="p" className={classes.footerSiteTitle}>
                                        {company.name}
                                    </Typography>
                                    <Typography component="p" className={classes.footerSiteSubTitle}>
                                        {company.slogan}
                                    </Typography>
                                    <Typography component="p" className={classes.footerSiteDescription}>
                                        {company.footerDescription}
                                    </Typography>
                                    <Typography component="div" className={classes.footerHours}>
                                        <div className={classes.footerHoursTitle}><strong>Orari di apertura</strong></div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Lunedì</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>14 - 21</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Martedi</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>09 - 12 | 14 - 21</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Mercoledì</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>14 - 21</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Giovedì</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>14 - 21</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Venerdi</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>09 - 12 | 14 - 21</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Sabato</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>Chiuso</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <p className={classes.footerHours}>Domenica</p>
                                            </Grid>
                                            <Grid item xs={4} justify="flex-start">
                                                <p className={classes.footerHours}>Chiuso</p>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </Grid>
                            </Grid>                            
                        </Grid>
                        <Grid item key="companyFooter2" md={4}>
                            <Grid container>
                                <Grid item key="companyAddress" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                        <RoomIcon sx={{ verticalAlign: 'bottom' }} /> {company.address}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyEmail" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                       <EmailIcon sx={{ verticalAlign: 'bottom' }} /> {company.email}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyPhone" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                        <LocalPhoneIcon sx={{ verticalAlign: 'bottom' }} /> {company.phone}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyMobilePhone" xs={12}>
                                    <Typography variant="body2" className={classes.footerCompanyInfo}>
                                        <WhatsAppIcon sx={{ verticalAlign: 'bottom' }} /> {company.mobile_phone}
                                    </Typography>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item key="companyFooter3" md={2}>
                            <ul className={classes.footerList}>
                            {footerLinks.map((footer) => (                            
                                <li key={`footer_${footer.title}`} className={classes.footerListItem}>
                                    <Link to={footer.link} component={NavLink} className={classes.footerLinks} title={footer.title}>{footer.title}</Link>
                                </li>                            
                            ))}
                            </ul>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        <Typography variant="body2" align="center">
                            {'Copyright © '}
                            <Link color="inherit" component={NavLink} to="/" sx={{ textDecoration: 'none' }} title="Ippocrates">
                                {company.name}
                            </Link>
                            {' '}
                            {new Date().getFullYear()}
                        </Typography>
                        <Typography variant="body2" align="center" mt={1}>
                            <Link color="inherit" href="https://www.actainfo.it" target="_blank" sx={{ textDecoration: 'none' }} title="Actainfo">
                                Design & Development by <img src={ActaLogo} className={classes.actalogo} width="20" alt="Logo Actainfo" /> Actainfo 
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}

export default Footer;