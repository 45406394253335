import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    departmentsWrapper: {
        marginTop: '150px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '190px',
        },
    },
    departmentsTitle: {
        textAlign: 'center',
        marginBottom: '50px',
        fontSize: '3.5rem',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
    },
}));

export { useStyles };