import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        marginTop: '-122px',
        backgroundColor: 'rgba(60, 112, 250, .8)',
        color: '#fff',
        fontWeight: '600',        
        padding: '30px 10px',
    },
    cardTitle: {
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
    },
    cardMedia: {
        paddingTop: '56.25%',
        minHeight: '300px !important',
        marginTop: '-24px',
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
    },
}));

export { useStyles };