import React from 'react';
import AppBarDrawer from '../AppBarDrawer/AppBarDrawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import PersonIcon from '@mui/icons-material/Person';

import { useStyles } from './HeaderStyles.js';


function Header({ company, headerLinks, socialLinks, isAuth, user, logout }) { 

    const classes = useStyles();

    return (
        <>            
        <Box className={classes.socialAppBar}>
            <Container maxWidth="xl">
            <Toolbar sx={{ minHeight: '40px !important' }}>
                <Typography 
                    variant="h6" 
                    color="inherit" 
                    noWrap
                    sx={{ paddingTop: '8px', flexGrow: 1 }}
                >
                    <Typography component="span"><Link to='/' component={NavLink} underline="none" className={classes.siteNameTop}>{company.name}</Link></Typography>
                </Typography>
                {socialLinks.map((social) => (
                    <Link 
                        href={social.link}
                        underline="none"
                        className={classes.socialLink}
                        title={social.title}
                        target="_blank"
                        key={social.title}
                    >
                        {social.icon}
                    </Link>
                ))}
                <Link href={`mailto:${company.email}`} className={classes.contactLink}>{company.email}</Link>
                <Link href={`tel:${company.phone}`} className={classes.contactLink}>{company.phone}</Link>
                <Link href={`tel:${company.mobile_phone}`} className={classes.contactLink}>{company.mobile_phone}</Link>
            </Toolbar>
            </Container>
        </Box>
        <AppBar position="sticky" className={classes.appBar}>
            <Container maxWidth="xl">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <AppBarDrawer headerLinks={headerLinks} socialLinks={socialLinks} company={company} isAuth={isAuth} logout={logout} /> 
                    <Box className={classes.mobileSiteWrapper}>
                        <Link to="/" component={NavLink}>
                            <img src={company.logo_alt} width="30" className={classes.mobileSiteImg} alt={company.name} />
                            <span className={classes.mobileSiteTitle}>{company.name}</span>
                        </Link>
                    </Box>
                </IconButton>
                <Typography 
                    variant="h6" 
                    color="inherit" 
                    noWrap
                    sx={{ flexGrow: 1 }}
                    className={classes.appBarWrapper}
                >
                {headerLinks.map((header) => (
                    
                    <Link 
                        component={NavLink}
                        to={header.link}
                        underline="none"
                        className={classes.link}
                        key={`header_${header.title}`}
                        title={header.title}
                    >
                        {header.title}
                    </Link>
                ))}
                </Typography>
                {!isAuth
                    ? 
                        <Button to="/login" component={NavLink} color="inherit" variant="outlined" className={classes.loginButton}>Area Protetta</Button>
                    : 
                        <>
                        <Typography className={classes.loggedMessage}><PersonIcon sx={{ verticalAlign: 'sub' }} /> {user}</Typography>
                        <Button href='/admin' color="inherit" variant="contained" className={classes.dashboardButton}>Dashboard</Button>
                        <Button onClick={logout} color="inherit" variant="outlined" className={classes.loginButton}>Logout</Button>
                        </>
                }
                
            </Toolbar>
            </Container>
        </AppBar>
        </>
    );
}

export default Header;