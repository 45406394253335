import { makeStyles } from '@material-ui/core/styles';

import DoctorHome from '../../assets/images/doctor_home.jpg'

const useStyles = makeStyles((theme) => ({
    homepageBackground: {
        backgroundImage: `url(${DoctorHome})`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        height: '785px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
           backgroundPosition: 'center right',
        },
    },
    homepageOverlay: {
        backgroundColor: 'rgba(0, 19, 65, .63)', 
        height: '100%',
    },
    siteInfoWrapper: {
        padding: '150px 20px',
    },
    siteTitle: {
        fontFamily: '"Poppins", sans-serif !important',
        fontWeight: '700 !important',
        color: '#ffffff',
        marginTop: '-20px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem !important',
            marginTop: 0,
        },
    },
    siteSubTitle: {
        fontSize: '2rem !important',
        fontFamily: '"Poppins", sans-serif !important',
        fontWeight: '600 !important',
        color: '#9eb9ff',
        marginTop: '-20px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem !important',
            marginTop: 0,
        },
    },
    siteLogo: {
        width: '120px',
        [theme.breakpoints.down('sm')]: {
            width: '80px',
        },
    },
    actionButton: {
        color: '#fff',
        border: '1px solid rgb(255 255 255)',
        padding: '10px 20px',
        marginTop: '10px',
        '&:hover': {
            background: "#fff",
            color: '#212121',
            border: '1px solid rgb(255 255 255)',
        },
    },
    homeActionWrapper: {
        marginTop: '40px',
    },
    homeActionButton: {
        padding: '50px',
        background: 'rgba(60, 112, 250, .95)',
        textAlign: 'center',
        color: '#fff',
    },
    homeActionLink: {
        textDecoration: 'none',
        fontFamily: '"Poppins", sans-serif !important',
        fontSize: '1.2rem',
        fontWeight: '600',
    },
}));

export { useStyles };