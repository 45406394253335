import { makeStyles } from '@material-ui/core/styles';

import Doctors from '../../assets/images/doctors.jpg'

const useStyles = makeStyles((theme) => ({
    homeContentWrapper: {
        backgroundImage: `url(${Doctors})`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        position: 'relative',
    },
    homepageOverlay: {
        backgroundColor: 'rgba(0, 15, 53, .7)', 
        height: '100%',
    },
    homeContentCEOCard: {
        maxWidth: '560px',
        marginTop: '-118px',
        [theme.breakpoints.down('md')]: {
           width: '100%',
        },
    },
    homeContentCEOPaper: {
        backgroundColor: '#3c70fa',
        paddingTop: '30px',
    },
    homeContentCEOTitle: {
        color: '#fff',
        paddingLeft: '20px',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif !important',
    },
    homeContentCEOSubTitle: {
        color: '#fff',
        paddingLeft: '40px',
        fontFamily: '"Poppins", sans-serif !important',
    },
    homeContentText: {
        fontFamily: '"Poppins", sans-serif !important',
        fontSize: '.875rem',
        color: '#fff',
    },
    homeContentCEOImage: {
        marginBottom: '-9px',
    },
    homeContentCard: {
        padding: '40px',
        [theme.breakpoints.down('md')]: {
           padding: '10px',
        },
    },
    homeContentTitle: {
        color: '#fff',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif !important',
    },
    homeContentList: {
        color: '#fff',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif !important',
        fontSize: '1rem',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mapWrapper: {
        marginBottom: '-70px',
    },
    iframe: {
        border: 'none',
    },
    homePrenotaSubitoButton: {        
        color: '#fff',        
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#475360',
            border: '1px solid rgb(255 255 255)',
        },
    },
    homeDepartmentLink: {
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export { useStyles };