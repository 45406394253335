import React from 'react';
import Box from '@mui/material/Box';
import BreadcrumbsBar from '../../components/BreadcrumbsBar/BreadcrumbsBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useStyles } from './PageHeaderStyles.js'

function MainLayout({ pageInfo }) {

    const classes = useStyles();
    
    if(!pageInfo || pageInfo === 0) return <p></p>;
    return (
        <Box>
            <Box className={classes.mainLayoutWrapper} >
                <Container maxWidth="xl">
                    <Typography variant="h1" className={classes.mainLayoutTitle}>
                        {pageInfo[0].title}
                    </Typography>
                    <Typography variant="body2" className={classes.mainLayoutSubTitle}>
                        {pageInfo[0].subtitle}
                    </Typography>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="xl" className={classes.mainLayoutSubWrapper}>
                    <BreadcrumbsBar />
                </Container>
            </Box>
        </Box>
    );
};

export default MainLayout;