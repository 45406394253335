import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useContext } from 'react';
import { ApiContext } from '../API/API';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import About from '../About/About';
import Services from '../Services/Services';
import Specialists from '../Specialists/Specialists';
import DoctorProfile from '../DoctorProfile/DoctorProfile';
import ServicePage from '../ServicePage/ServicePage';
import Contacts from '../Contacts/Contacts';
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import LoginForm from '../LoginForm/LoginForm';
import Privacy from '../Privacy/Privacy';
import HomePage from '../../views/HomePage/HomePage';
import CssBaseline from '@mui/material/CssBaseline';
import ScrollToTop from '../../utils/ScrollToTop';
import Box from '@mui/material/Box';


function AppRoutes() {

    const api = useContext(ApiContext); 

    return (
        <Router>
            <ScrollToTop />
            <CssBaseline />
            <Box sx={{ flexGrow: 1, height: '100%' }}>
                <Header company={api.company} headerLinks={api.headerLinks} socialLinks={api.socialLinks} isAuth={api.isAuth} user={api.user} logout={api.logout} />
                <Switch>
                    <Route exact path="/">
                        <HomePage listDepartments={api.listDepartments} company={api.company} actionLinks={api.actionLinks} />
                    </Route>
                    <Route exact path="/chi-siamo">
                        <About company={api.company} />
                    </Route>
                    <Route exact path="/servizi">
                        <Services listDepartments={api.listDepartments} />
                    </Route>
                    <Route exact path="/specialisti">
                        <Specialists listDepartments={api.listDepartments} listDoctors={api.listDoctors} />
                    </Route>
                    <Route exact path="/contatti">
                        <Contacts company={api.company} />
                    </Route>
                    <Route exact path="/specialisti/:slug">
                        <DoctorProfile doctorProfile={api.doctorProfile} />
                    </Route>
                    <Route exact path="/servizi/:slug">
                        <ServicePage servicePage={api.servicePage} doctorsDepartment={api.doctorsDepartment} />
                    </Route>
                    <Route exact path="/prenota-una-visita">
                        <AppointmentForm company={api.company} listDoctors={api.listDoctors} submitAppointment={api.submitAppointment} />
                    </Route>
                    <Route exact path="/login">
                        <LoginForm login={api.login} company={api.company} />
                    </Route>
                    <Route exact path="/privacy-policy">
                        <Privacy />
                    </Route>
                </Switch>
                <Footer company={api.company} footerLinks={api.footerLinks} />
            </Box>           
        </Router>
    );

}

export default AppRoutes;
