import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    serviceName: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '500',
        color: '#1e375f',
        [theme.breakpoints.down('md')]: {
           fontSize: '1.3rem',
        },
    },
    serviceDescription: {
        fontFamily: "'Poppins', sans-serif",
        whiteSpace: 'pre-line',
        fontSize: '1.2rem',
    },
    serviceSubTitle: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.8rem',
        color: 'rgba(0, 0, 0, .6)',
        fontWeight: '500',
    },
    serviceImg: {
        maxWidth: '100%',
    },
    doctorCard: {
        backgroundColor: '#edf2ff',
        marginBottom: '20px',
    },
    doctorCardName: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
    },
    doctorCardSubName: {
        fontFamily: "'Poppins', sans-serif",
        marginTop: '20px',
    },
    doctorCardDepartments: {
        fontFamily: "'Poppins', sans-serif",
    },
    doctorProfileLink: {
        textDecoration: 'none',
    },
}));

export { useStyles };