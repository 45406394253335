import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useContext } from 'react';
import { ApiContext } from '../API/API';

function ProtectedRoute({ component: Component, ...restOfProps }) {

  const api = useContext(ApiContext);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        api.isAuth ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;