import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { useStyles } from './DeleteAppointmentStyles.js';

function DeleteAppointment({ appointmentDetails, deleteAppointment }) {

    const classes = useStyles();

    const history = useHistory();

    const { appointmentID } = useParams();

    const [appointment, setAppointment] = useState();

    useEffect(() => {
        (async() => {
            const appointment = await appointmentDetails(appointmentID);
            setAppointment(appointment);          
        })();
    }, [appointmentDetails, appointmentID]);

    const handleSubmit = async(e) => {
        e.preventDefault();

        await deleteAppointment(appointmentID);

        let status;

        if(appointment.status === 'waiting'){
            status = 'in-attesa';
        } else if(appointment.status === 'confirmed'){
            status = 'confermati';
        } else {
            status = 'chiusi';
        }
        
        history.push({
            pathname: '/admin/elenco-appuntamenti/stato/'+ status +'/?action=deleted',
        });
        window.location.reload();
    }

    if(!appointment || appointment.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return (
        <Box>
            <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={10}>
                        <Typography className={classes.appointmentTitle}>
                            Elimina Appuntamento
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} sx={{ textAlign: 'right' }}>
                        <Button 
                                variant="contained"
                                className={classes.backButton}
                                type="submit"
                                color="secondary"
                                href={'/admin/elenco-appuntamenti/gestisci/' + appointment.id}
                            >
                            Annulla
                        </Button> 
                    </Grid>
                </Grid>  
                <Divider />
                <Box sx={{ textAlign: 'center' }} mt={5}> 
                    <Box className={classes.appointmentWrapper}> 
                        <Grid container>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography className={classes.patientInfo}><strong>Data Richiesta:</strong> {appointment.dateFormat}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography className={classes.patientInfo}><strong>Paziente:</strong> {appointment.patient_last_name} {appointment.patient_first_name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography className={classes.patientInfo}><strong>Email:</strong> {appointment.patient_email}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography className={classes.patientInfo}><strong>Telefono:</strong> {appointment.patient_phone}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography className={classes.patientInfo}><strong>Specialista:</strong> {appointment.doctorLastName} {appointment.doctorFirstName}</Typography>                        
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography mt={5} mb={2} className={classes.poppins}>Procedere con l'eliminazione?</Typography>
                    <Button 
                        variant="contained"
                        className={classes.deleteButton}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Conferma Eliminazione
                    </Button>   
                </Box>      
            </Container>
        </Box>
    );
}

export default DeleteAppointment;