import React from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useStyles } from './ContactsStyles.js'

function Contacts({ company }) {

    const classes = useStyles();

    const pageInfo = [
        {
            title: 'Contatti',
            subtitle: 'Ippocrates - Nucleo Medico Specialistico',
        },
    ];

    return(
        <>
            <PageHeader pageInfo={pageInfo} />
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ padding: '30px' }}>
                            <img src={company.logo} width="120" alt={company.name} />
                            <Typography variant="h2" className={classes.companyInfoTitle}>
                                {company.name}
                            </Typography>
                            <Typography variant="h4" component="h3" mb={5} className={classes.companyInfoSubTitle}>
                                {company.slogan}
                            </Typography>
                            <Grid container>
                                <Grid item key="companyAddress" xs={12}>
                                    <Typography variant="body2" className={classes.companyInfo}>
                                        <RoomIcon sx={{ verticalAlign: 'bottom' }} /> {company.address}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyEmail" xs={12}>
                                    <Typography variant="body2" className={classes.companyInfo}>
                                       <EmailIcon sx={{ verticalAlign: 'bottom' }} /> {company.email}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyPhone" xs={12}>
                                    <Typography variant="body2" className={classes.companyInfo}>
                                        <LocalPhoneIcon sx={{ verticalAlign: 'bottom' }} /> {company.phone}
                                    </Typography>
                                </Grid>
                                <Grid item key="companyPhone" xs={12}>
                                    <Typography variant="body2" className={classes.companyInfo}>
                                        <WhatsAppIcon sx={{ verticalAlign: 'bottom' }} /> {company.mobile_phone}
                                    </Typography>
                                </Grid>
                            </Grid>  
                            <Typography component="div" className={classes.companyHoursWrapper}>
                                <div className={classes.companyHoursTitle}><strong>Orari di apertura</strong></div>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Lunedì</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>14 - 21</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Martedi</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>09 - 12 | 14 - 21</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Mercoledì</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>14 - 21</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Giovedì</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>14 - 21</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Venerdi</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>09 - 12 | 14 - 21</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Sabato</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>Chiuso</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <p className={classes.companyHours}>Domenica</p>
                                    </Grid>
                                    <Grid item xs={4} justify="flex-start">
                                        <p className={classes.companyHours}>Chiuso</p>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.1595331504545!2d14.010691415905898!3d42.6791628791668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133196a21fc78691%3A0x6fe3f18bdec761f0!2sStudio%20Medico%20Associato%20Ippocrates!5e0!3m2!1sit!2sit!4v1634312839863!5m2!1sit!2sit" width="100%" height="450" allowFullScreen="" loading="lazy" className={classes.iframe} title="Google Map"></iframe>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Contacts;