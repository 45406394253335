import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';

import { useStyles } from './AppointmentsListStyles.js';


function DataTable({ appointmentsList, status }) {

    const classes = useStyles();

    const [appointments, setAppointments] = useState(null);
    
    useEffect(() => {
        (async() => {
            const appointments = await appointmentsList(status);
            setAppointments(appointments);
        })();
    }, [appointmentsList, status]);

    let columns = [];

    if(status === 'in-attesa') {
        columns = [
            { field: 'dateFormat', headerName: 'Data Richiesta', flex: 1, },
            {
                field: 'patientFullName',
                headerName: 'Paziente',
                flex: 1,
                renderCell: (params) => (
                    <Link to={`/admin/elenco-appuntamenti/${params.id}`} className={classes.tableLink} component={NavLink}>
                        {`${params.getValue(params.id, 'patient_last_name') || ''} ${
                        params.getValue(params.id, 'patient_first_name') || ''
                    }`}</Link>
                )
            },
            { field: 'patient_email', headerName: 'Email', flex: 1, },
            { field: 'patient_phone', headerName: 'Telefono', flex: 1, },
            {
                field: 'doctorFullName',
                headerName: 'Specialista',
                flex: 1,
                valueGetter: (params) =>
                  `${params.getValue(params.id, 'doctorLastName') || ''} ${
                    params.getValue(params.id, 'doctorFirstName') || ''
                }`,
            },
        ];
    } else {
        columns = [
            { field: 'dateFormat', headerName: 'Data Richiesta', flex: 1, },
            {
                field: 'patientFullName',
                headerName: 'Paziente',
                flex: 1,
                valueGetter: (params) =>
                  `${params.getValue(params.id, 'patient_last_name') || ''} ${
                    params.getValue(params.id, 'patient_first_name') || ''
                }`,
                renderCell: (params) => (
                    <Link to={`/admin/elenco-appuntamenti/${params.id}`} className={classes.tableLink} component={NavLink}>
                        {`${params.getValue(params.id, 'patient_last_name') || ''} ${
                        params.getValue(params.id, 'patient_first_name') || ''
                    }`}</Link>
                )
            },
            { field: 'patient_email', headerName: 'Email', flex: 1.2, },
            { field: 'patient_phone', headerName: 'Telefono', flex: 1, },
            {
                field: 'doctorFullName',
                headerName: 'Specialista',
                flex: 1,
                valueGetter: (params) =>
                  `${params.getValue(params.id, 'doctorLastName') || ''} ${
                    params.getValue(params.id, 'doctorFirstName') || ''
                }`,
            },
            { field: 'appointmentDateFormat', headerName: 'Appuntamento', flex: 1.2, },
            { field: 'room', headerName: 'Stanza', flex: 0.7, },
            { field: 'duration', headerName: 'Durata', flex: 0.7, },
        ];
    }    

    if(!appointments || appointments.length === 0) return <p></p>;
    return (
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={appointments}
            columns={columns}
            autoHeight
            localeText={itIT.props.MuiDataGrid.localeText}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        </div>
    );
}

function AppointmentsList({ appointmentsList }) {

    const classes = useStyles();

    const { status } = useParams();

    let actionResult = new URLSearchParams(window.location.search).get('action');

    useEffect(() => {
        if(actionResult) {
            actionResult = actionResult.replace(/(<([^>]+)>)/gi, "");
        } else {
            actionResult = '';
        }  
    });

    let pageTitle = '';
    let subTitle = '';

    if(status === 'in-attesa'){
        pageTitle = 'Richieste in attesa';
        subTitle = 'Richieste di appuntmenti in attesa di conferma'
    } else if(status === 'confermati'){
        pageTitle = 'Appuntamenti Confermati';
        subTitle = 'Appuntamenti fissati con il paziente'
    } else if(status === 'chiusi'){
        pageTitle = 'Appuntamenti Chiusi';
        subTitle = 'Visite effettuate'
    }
    else {
        window.location.replace('/admin');
    }

    return(
        <>
            <Box>
                <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={10}>
                            <Typography className={classes.appointmentsListTitle}>
                                {pageTitle}
                            </Typography>
                            <Typography className={classes.appointmentsListSubTitle}>
                                {subTitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} sx={{ textAlign: 'right' }}>
                            <Button variant="contained" href={'/admin/elenco-appuntamenti/crea/'} className={classes.manageAppointment}>+ Aggiungi</Button>
                        </Grid>
                    </Grid>
                    {(actionResult === 'Sent') && <p><Alert severity="success" className={classes.actionResult}>Appuntamento registrato. Il messaggio è stato inviato correttamente.</Alert></p>}
                    {(actionResult === 'Saved') && <p><Alert severity="success" className={classes.actionResult}>Appuntamento registrato.</Alert></p>}
                    {(actionResult === 'deleted') && <p><Alert severity="success" className={classes.actionResult}>Appuntamento eliminato.</Alert></p>}
                    <Box>      
                        <DataTable appointmentsList={appointmentsList} status={status} />
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default AppointmentsList;