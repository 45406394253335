import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardMainWrapper: {
        marginTop: '80px',
    },
    appointmentTitle: {
        color: '#1e375f',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '10px',
    },
    patientInfo: {
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
        marginTop: '10px',
        whiteSpace: 'pre-line',
        padding: '10px',
        border: '1px solid #212121',
        wordBreak: 'break-word',
    },
    poppins: {
        fontFamily: "'Poppins', sans-serif",
    },
    deleteButton: {
        width: '100%',
        backgroundColor: '#b71c1c',
        '&:hover': {
            backgroundColor: '#d50000',
        }
    },
    backButton: {
        width: '100%',
        backgroundColor: '#9e9e9e',
        '&:hover': {
            backgroundColor: '#757575',
        }
    }
}));

export { useStyles };