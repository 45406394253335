import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appointmentFormGrid: {
        paddingLeft: '30px', 
        paddingRight: '30px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    formWrapper: {
        padding: '40px',
        backgroundColor: '#f9f9f9',
        [theme.breakpoints.up('md')]: {
            minHeight: '500px',
        },
    },
    formTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
        fontWeight: '600',
    },
    companyInfoTitle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
        },
    },
    companyInfoSubTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#6f97ff',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    submit: {
        padding: '20px',
    },
    formResponse: {
        fontFamily: "'Poppins', sans-serif",
    },
    inputField: {
        backgroundColor: '#fff',
    },
    error: {
        color: '#d32f2f',
    }
}));

export { useStyles };