import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardMainWrapper: {
        marginTop: '80px',
    },
    appointmentsListTitle: {
        color: '#1e375f',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginLeft: '5px',
    },
    appointmentsListSubTitle: {
        color: '#5a5a5a',
        fontFamily: "'Poppins', sans-serif",
        marginBottom: '20px',
        marginLeft: '5px',
    },
    tableLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    manageAppointment: {
        width: '100%',
        backgroundColor: '#1e375f',
        color: '#fff',
        border: '1px solid #212121',
        padding: '5px 15px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#30538c',
            color: '#fff',
        },
    },
}));

export { useStyles };