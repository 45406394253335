import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    doctorName: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
        fontWeight: '500',
        [theme.breakpoints.down('md')]: {
           fontSize: '1.8rem',
        },
    },
    doctorSubName: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.3rem',
        color: 'rgba(0, 0, 0, .6)',
        fontWeight: '500',
        [theme.breakpoints.down('md')]: {
           fontSize: '1.3rem',
        },
    },
    doctorDescription: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.2rem',
    },
    doctorDepartments: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.2rem',
        [theme.breakpoints.down('md')]: {
           fontSize: '1rem',
        },
    },
    doctorInfo: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
    },
    doctorImage: {
        maxWidth: '100%',
    },
}));

export { useStyles };