import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { NavLink } from 'react-router-dom';

import { useStyles } from './DoctorProfileStyles.js';

const Profile = ({ doctorProfile }) => {

    const classes = useStyles();

    const { slug } = useParams();
    const [profile, setProfile] = useState(null);
    
    useEffect(() => {
        (async() => {
            const profile = await doctorProfile(slug);
            setProfile(profile);
        })();
    }, [doctorProfile, slug]);

    if(!profile || profile.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
         <Box sx={{ padding: '30px' }}>
            <Grid container >
                <Grid item xs={12} sm={12} md={4} sx={{ padding: '20px' }}>
                    <img src={profile.image} className={classes.doctorImage} />
                </Grid>
                <Grid item xs={12} sm={12} md={8} sx={{ padding: '20px' }}>
                    <Typography variant="h2" className={classes.doctorName}>
                        {profile.title} {profile.last_name} {profile.first_name}
                    </Typography>
                    <Typography mt={3} className={classes.doctorDescription} >
                        {profile.description}
                    </Typography>
                    <Typography mt={3} className={classes.doctorSubName}>
                        Aree di interesse
                    </Typography>
                    {profile.departmentNames.map((department) => {
                        return(
                            <Typography key={department.slug} className={classes.doctorDepartments}>
                                {department.name}
                            </Typography>
                        );                
                    })}
                    <Typography mt={5} mb={3} className={classes.doctorSubName}>
                        Informazioni di contatto
                    </Typography>
                    <Grid container>
                        <Grid item key="doctorEmail" xs={12} mb={3}>
                            <Typography variant="body2" className={classes.doctorInfo}>
                               <EmailIcon sx={{ verticalAlign: 'bottom' }} /> {profile.email}
                            </Typography>
                        </Grid>
                        <Grid item key="doctorPhone" xs={12} mb={3}>
                            <Typography variant="body2" className={classes.doctorInfo}>
                                <LocalPhoneIcon sx={{ verticalAlign: 'bottom' }} /> {profile.phone}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button 
                        variant="contained"
                        className={classes.actionButton}
                        component={NavLink}
                        to={`/prenota-una-visita?doctor=${profile.id}`}
                    >
                        Richiedi un appuntamento
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

function DoctorProfile({ doctorProfile }) {

    const pageInfo = [
        {
            title: 'Specialisti',
            subtitle: 'Si effettuano visite mediche in numerose branche specialistiche',
        },
    ];

    return(
        <>
            <PageHeader pageInfo={pageInfo} />
            <Box>
                <Container maxWidth="xl">
                    <Profile doctorProfile={doctorProfile} />
                </Container>
            </Box>
        </>
    );
};

export default DoctorProfile;