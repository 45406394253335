import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { useStyles } from './AppBarDrawerStyles.js'

export default function AppBarDrawer({ headerLinks, socialLinks, company, isAuth, logout }) {

  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.mobileDrawerWrapper}
    >
      <List>
        {headerLinks.map((header) => (
          <ListItem button key={`mobile_header_${header.title}`}>
              <Link 
                component={NavLink}
                to={header.link}
                underline="none"
                className={classes.mobileLink}
                key={`mobile_header2_${header.title}`}
              >
                <ListItemIcon key={`mobile_header3_${header.title}`} className={classes.mobileLink}>{header.title}</ListItemIcon>
              </Link>            
          </ListItem>
        ))}
      </List>
      {!isAuth
        ? 
          <Button component={NavLink} to='/login' color="inherit" className={classes.loginButton} variant="outlined">Area Protetta</Button>
        : 
          <>
            <Button href='/admin' color="inherit" variant="contained" className={classes.dashboardButton}>Dashboard</Button>
            <Button onClick={logout} color="inherit" variant="outlined" className={classes.logoutButton}>Logout</Button>
          </>
      }
    </Box>
    <Divider />
    <Box className={classes.mobileCompanyInfo}>
        {socialLinks.map((social) => (
          <Link 
              href={social.link}
              underline="none"
              className={classes.mobileSocialLink}
              title={social.title}
              target="_blank"
              key={`mobile_header_${social.title}`}
          >
            {social.icon}
          </Link>
        ))}
        <div>
          <Link href={`mailto:${company.email}`} className={classes.mobileContactLink}>{company.email}</Link>
        </div>
        <div>
          <Link href={`tel:${company.phone}`} className={classes.mobileContactLink}>{company.phone}</Link>
        </div>
        <div>
          <Link href={`tel:${company.mobile_phone}`} className={classes.mobileContactLink}>{company.mobile_phone}</Link>
        </div>
    </Box>
    </>
  );

  return (
    <div>
      <MenuIcon onClick={toggleDrawer('left', true)} className={classes.hamburger} />
      {['left'].map((anchor) => (          
        <Drawer
          anchor="left"
          open={state[anchor]}
          onClose={toggleDrawer("left", false)}
          key={anchor}
        >
          {list(anchor)}
        </Drawer>        
      ))}
    </div>
  );
}
