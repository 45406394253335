import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    aboutContent: {
        marginTop: '50px',
    },
    aboutContentTitle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
    },
    aboutContentSubTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#6f97ff',
    },
    aboutContentDescription: {
        fontFamily: "'Poppins', sans-serif",
        whiteSpace: 'pre-line',
        fontSize: '1.2rem',
    },
}));

export { useStyles };