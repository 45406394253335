import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    companyInfoTitle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#1e375f',
    },
    companyInfoSubTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#6f97ff',
    },
    companyInfo: {
        marginTop: '15px',
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
    },
    iframe: {
        border: 'none',
    },
    companyHoursTitle: {
        color: '#1e375f',
        marginBottom: '10px',
        fontFamily: "'Poppins', sans-serif",
    },
    companyHoursWrapper: {
        marginTop: '20px',
    },
    companyHours: {
        marginTop: '0',
        marginBottom: '0',
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
    }
}));

export { useStyles };