import * as React from 'react';
import { ViewState, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AppointmentForm,
  GroupingPanel,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import domtoimage from "dom-to-image";
import { connectProps } from '@devexpress/dx-react-core';
import { withStyles, makeStyles, alpha } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import classNames from 'clsx';
import { ApiContext } from '../../API/API';
import { priorities } from './CalendarData/priorities';

const grouping = [{
    resourceName: 'room',
}];


const styles = theme => ({
  flexibleSpace: {
    margin: '0 auto 0 0',
  },
  prioritySelector: {
    marginLeft: theme.spacing(2),
    minWidth: 140,
    '@media (max-width: 500px)': {
      minWidth: 0,
      fontSize: '0.75rem',
      marginLeft: theme.spacing(0.5),
    },
  },
});

const usePrioritySelectorItemStyles = makeStyles(({ palette, spacing }) => ({
  bullet: ({ color }) => ({
    backgroundColor: color ? color[400] : palette.divider,
    borderRadius: '50%',
    width: spacing(2),
    height: spacing(2),
    marginRight: spacing(2),
    display: 'inline-block',
  }),
  prioritySelectorItem: {
    display: 'flex',
    alignItems: 'center',
  },
  priorityText: {
    '@media (max-width: 500px)': {
      display: 'none',
    },
  },
  priorityShortText: {
    '@media (min-width: 500px)': {
      display: 'none',
    },
  },
}));

const groupingStyles = ({ spacing }) => ({
  ...priorities.reduce((acc, priority) => ({
    ...acc,
    [`cell${priority.text.replace(' ', '')}`]: {
      backgroundColor: alpha(priority.color[400], 0.1),
      '&:hover': {
        backgroundColor: alpha(priority.color[400], 0.15),
      },
      '&:focus': {
        backgroundColor: alpha(priority.color[400], 0.2),
      },
    },
    [`headerCell${priority.text.replace(' ', '')}`]: {
      backgroundColor: alpha(priority.color[400], 0.1),
      '&:hover': {
        backgroundColor: alpha(priority.color[400], 0.1),
      },
      '&:focus': {
        backgroundColor: alpha(priority.color[400], 0.1),
      },
    },
  }), {}),
  icon: {
    paddingLeft: spacing(1),
    verticalAlign: 'middle',
  },
});

const DayViewTimeTableCell = withStyles(groupingStyles, { name: 'DayViewTimeTableCell' })(({
  groupingInfo, classes, ...restProps
}) => {

  const groupId = groupingInfo[0].id;
  return (
    <DayView.TimeTableCell
      className={classNames({
        [classes.cellStanza1]: groupId === 1,
        [classes.cellStanza2]: groupId === 2,
        [classes.cellStanza3]: groupId === 3,
        [classes.cellStanza4]: groupId === 4,
        [classes.cellStanza5]: groupId === 5,
      })}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
});

const DayViewDayScaleCell = withStyles(groupingStyles, { name: 'DayViewDayScaleCell' })(({
  groupingInfo, classes, ...restProps
}) => {
  const groupId = groupingInfo[0].id;
  return (
    <DayView.DayScaleCell
      className={classNames({
        [classes.headerCellStanza1]: groupId === 1,
        [classes.headerCellStanza2]: groupId === 2,
        [classes.headerCellStanza3]: groupId === 3,
        [classes.headerCellStanza4]: groupId === 4,
        [classes.headerCellStanza5]: groupId === 5,
      })}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
});

const WeekViewTimeTableCell = withStyles(groupingStyles, { name: 'WeekViewTimeTableCell' })(({
  groupingInfo, classes, ...restProps
}) => {
  const groupId = groupingInfo[0].id;
  return (
    <WeekView.TimeTableCell
      className={classNames({
        [classes.cellStanza1]: groupId === 1,
        [classes.cellStanza2]: groupId === 2,
        [classes.cellStanza3]: groupId === 3,
        [classes.cellStanza4]: groupId === 4,
        [classes.cellStanza5]: groupId === 5,
      })}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
});

const WeekViewDayScaleCell = withStyles(groupingStyles, { name: 'WeekViewDayScaleCell' })(({
  groupingInfo, classes, ...restProps
}) => {
  const groupId = groupingInfo[0].id;
  return (
    <WeekView.DayScaleCell
      className={classNames({
        [classes.headerCellStanza1]: groupId === 1,
        [classes.headerCellStanza2]: groupId === 2,
        [classes.headerCellStanza3]: groupId === 3,
        [classes.headerCellStanza4]: groupId === 4,
        [classes.headerCellStanza5]: groupId === 5,
      })}
      groupingInfo={groupingInfo}
      {...restProps}
    />
  );
});

const GroupingPanelCell = withStyles(groupingStyles, { name: 'GroupingPanelCell' })(({
  group, classes, ...restProps
}) => {
  const groupId = group.id;
  return (
    <GroupingPanel.Cell
      className={classNames({
        [classes.headerCellStanza1]: groupId === 1,
        [classes.headerCellStanza2]: groupId === 2,
        [classes.headerCellStanza3]: groupId === 3,
        [classes.headerCellStanza4]: groupId === 4,
        [classes.headerCellStanza5]: groupId === 5,
      })}
      group={group}
      {...restProps}
    >
    </GroupingPanel.Cell>
  );
});

const PrioritySelectorItem = ({
  color, text: resourceTitle,
}) => {
  const text = resourceTitle || 'Tutte le stanze';
  const shortText = resourceTitle ? text.substring(0, 1) : 'All';
  const classes = usePrioritySelectorItemStyles({ color });

  return (
    <div className={classes.prioritySelectorItem}>
      <span className={classes.bullet} />
      <span className={classes.priorityText}>{text}</span>
      <span className={classes.priorityShortText}>{shortText}</span>
    </div>
  );
};

const PrioritySelector = withStyles(styles, { name: 'PrioritySelector' })(({
  classes, priorityChange, priority,
}) => {
  const currentPriority = priority > 0 ? priorities[priority - 1] : {};
  return (
    <FormControl className={classes.prioritySelector}>
      <Select
        disableUnderline
        value={priority}
        onChange={(e) => {
          priorityChange(e.target.value);
        }}
        renderValue={() => (
          <PrioritySelectorItem text={currentPriority.text} color={currentPriority.color} />
        )}
      >
        <MenuItem value={0}>
          <PrioritySelectorItem />
        </MenuItem>
        {priorities.map(({ id, color, text }) => (
          <MenuItem value={id} key={id.toString()}>
            <PrioritySelectorItem color={color} text={text} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const FlexibleSpace = withStyles(styles, { name: 'FlexibleSpace' })(({
  classes, priority, priorityChange, ...restProps
}) => (
  <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
    <PrioritySelector priority={priority} priorityChange={priorityChange} />
  </Toolbar.FlexibleSpace>
));

const Appointment = ({ data, children, style, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      minHeight: '61px',
      padding: '2px',
    }}
  >
    <Link to={`/admin/elenco-appuntamenti/${data.id}`} component={NavLink} sx={{ textDecoration: 'none' }}>
        <Typography sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '12px', color: '#ffffff', paddingTop:'2px', paddingLeft: '7px', fontWeight: '700' }}>{data.doctorTitle} {data.doctorFirstName} {data.doctorLastName}</Typography>
        <Typography sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '12px', color: '#ffffff', paddingLeft: '10px', marginTop: '-5px' }}>{children}</Typography>
    </Link>
  </Appointments.Appointment>
);

const filterTasks = (data, room) => data.filter(item => (
  !room || item.room === room
));

const exportToImage = async (scheduler, format, exportFunc) => {
  try {
    const dataUrl = await exportFunc(scheduler);
    const link = document.createElement("a");
    link.download = `Calendario.${format}`;
    link.href = dataUrl;
    link.click();
  } catch (err) {
    console.error("oops, something went wrong!", err);
  }
};

const exportToJpeg = scheduler =>
  exportToImage(scheduler, "jpeg", domtoimage.toJpeg);

const handleExport = action => () => {
  const scheduler = document.querySelector("#widget-container");
  action(scheduler);
};

export default class Calendar extends React.PureComponent {

  static contextType = ApiContext;
  
  constructor(props) {
    super(props);

    this.state = {
      currentDate: new Date(),
      currentViewName: 'Oggi',
      data: [],
      currentPriority: 0,
      resources: [{
        fieldName: 'room',
        title: 'Stanza',
        instances: priorities,
      }],
    };

    this.currentViewNameChange = (currentViewName) => {
      this.setState({ currentViewName });
    };

    this.currentDateChange = (currentDate) => {
      this.setState({ currentDate });
    };

    this.priorityChange = (value) => {
      const { resources } = this.state;
      const nextResources = [{
        ...resources[0],
        instances: value > 0 ? [priorities[value - 1]] : priorities,
      }];

      this.setState({ currentPriority: value, resources: nextResources });
    };

    this.flexibleSpace = connectProps(FlexibleSpace, () => {
      const { currentPriority } = this.state;
      return {
        priority: currentPriority,
        priorityChange: this.priorityChange,
      };
    });
  }

    componentDidUpdate() {
      this.flexibleSpace.update(); 
    }

    async componentDidMount() {
       let appointments = await this.context.appointmentsList('confermati');
       const listOfScheduleData = await Promise.all(appointments);

       this.setState({
          //data: [...this.state.data, ...listOfScheduleData],
          data: listOfScheduleData,
       });
    }

  render() {
    const {
      data, currentDate, currentViewName, currentPriority, resources,
    } = this.state;

    return (
      <>
      <Button onClick={handleExport(exportToJpeg)} variant="contained" color="primary">Stampa Calendario</Button>
      <Paper id="widget-container">
        <Scheduler
          data={filterTasks(data, currentPriority)}
          height={'100%'}
          locale="it-IT"
        >
          <ViewState
            currentDate={currentDate}
            currentViewName={currentViewName}
            onCurrentViewNameChange={this.currentViewNameChange}
            onCurrentDateChange={this.currentDateChange}
          />
          <GroupingState
            grouping={grouping}
          />

          <DayView
            startDayHour={7}
            endDayHour={22}
            timeTableCellComponent={DayViewTimeTableCell}
            dayScaleCellComponent={DayViewDayScaleCell}
            intervalCount={1}
            name="Oggi"
            cellDuration={15}
          />
          <WeekView
            startDayHour={7}
            endDayHour={22}
            excludedDays={[0]}
            name="Settimana"
            timeTableCellComponent={WeekViewTimeTableCell}
            dayScaleCellComponent={WeekViewDayScaleCell}
            cellDuration={15}
          />

          <Appointments appointmentComponent={Appointment} data={data} />
          <Resources
            data={resources}
          />
          <IntegratedGrouping />

          <GroupingPanel
            cellComponent={GroupingPanelCell}
          />
          <Toolbar flexibleSpaceComponent={this.flexibleSpace} />
          <DateNavigator />
          <ViewSwitcher />
          
          <AppointmentForm readOnly />
        </Scheduler>
      </Paper>
      </>
    );
  }
}
