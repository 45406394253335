import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    hamburger: {
        marginTop: '10px',
    },
    mobileCompanyInfo: {
        textAlign: 'center',
        marginTop: '20px',
    },
    mobileDrawerWrapper: {
        padding: '20px',
    },
    mobileLink: {
        color: '#585a5c !important',
        fontSize: '.875rem',
        width: '100%',
    },
    mobileSocialLink: {
        color: '#585a5c',
    },
    mobileContactLink: {
        color: '#585a5c',
        fontSize: '.875rem',
        fontFamily: "'Poppins', sans-serif",
        textDecoration: 'none',
    },
    loginButton: {
        marginLeft: '14px',
    },
    logoutButton: {
        marginLeft: '14px',
        width: '125px',
    },
    dashboardButton: {
        marginLeft: '14px',
        width: '125px',
        marginBottom: '5px',
        border: '1px solid #212121',
        boxShadow: 'none',
    },
}));

export { useStyles };