import React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

import { useStyles } from './DoctorsStyles.js';

const DepartmentsList = ({ listDepartments }) => {

    const classes = useStyles();

    const [departments, setDepartments] = useState(null);
    
    useEffect(() => {
        (async() => {
            const departments = await listDepartments();
            setDepartments(departments);
        })();
    }, [listDepartments]);

    if (!departments || departments.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return (
        <Paper elevation={1} className={classes.departmentSideBar}>
            <Typography variant="body2" className={classes.departmentsTitle}>
                Branche specialistiche
            </Typography>
            <List>
            {departments.map((department) => {
                return(
                    <ListItem key={`department_${department.slug}`} className={classes.departmentListItem}>
                        <Link
                            component={NavLink}
                            color="textPrimary"
                            to={'servizi/' + department.slug}
                            className={classes.departmentsLink}
                            key={`department2_${department.slug}`}
                        >
                            {department.name}
                        </Link>
                    </ListItem>
                );                           
            })}
            </List> 
        </Paper>
    );
};

const Doctors = ({ listDoctors, listDepartments }) => {

    const [doctors, setDoctors] = useState(null);
    
    useEffect(() => {
        (async() => {
            const doctors = await listDoctors();
            setDoctors(doctors);
        })();
    }, [listDoctors]);

    const classes = useStyles();
    
    if (!doctors || doctors.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return (
        <React.Fragment>
            <Container maxWidth="xl" component="main" className={classes.departmentsWrapper}>
                <Grid container spacing={5} >
                    <Grid item xs={12} sm={12} md={4}>
                        <DepartmentsList listDepartments={listDepartments} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        {doctors.map((doctor) => {
                            return(                                
                                <Card key={doctor.slug} className={classes.doctorCard}>
                                    <CardContent key={`doctor_${doctor.slug}`}>
                                        <Typography variant="h5" component="div" className={classes.doctorCardName}>
                                            {doctor.title} {doctor.last_name} {doctor.first_name} 
                                        </Typography>
                                        <Typography color="text.secondary" className={classes.doctorCardSubName}>
                                            Aree di interesse
                                        </Typography>                                    
                                        {doctor.departmentNames.map((department) => {
                                            return(
                                                <Typography key={`doctor_department_${department.slug}`} sx={{ fontSize: 14 }} gutterBottom className={classes.doctorCardDepartments}>
                                                    {department.name}
                                                </Typography>
                                            );
                                            
                                        })}                                            
                                    </CardContent>
                                    <CardActions key={`doctor3_${doctor.slug}`}>
                                        <Link
                                            component={NavLink}
                                            color="textPrimary"
                                            to={'specialisti/' + doctor.slug}
                                            className={classes.doctorProfileLink}
                                            key={doctor.slug}
                                        >
                                            <Button size="small">Visualizza profilo</Button>
                                        </Link>
                                    </CardActions>
                                </Card>                                
                            );
                        })}
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Doctors;