import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainLayoutWrapper: {
        backgroundColor: '#0355d5',
        padding: '50px',
        [theme.breakpoints.down('md')]: {
           padding: '20px',
        },
        [theme.breakpoints.down('xs')]: {
           padding: '20px 10px',
        },
    },
    mainLayoutSubWrapper: {
        marginTop: '50px',
    },
    mainLayoutTitle: {
        fontSize: '3.5rem',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        color: '#fff',
        paddingLeft: '30px',
        [theme.breakpoints.down('md')]: {
           paddingLeft: '0',
        },
    },
    mainLayoutSubTitle: {
        color: '#fff',
        paddingLeft: '30px',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
           paddingLeft: '0',
        },
    },
}));

export { useStyles };