import { deepPurple, indigo, blue, cyan, teal  } from '@material-ui/core/colors';

export const priorities = [
  { id: 1, text: 'Stanza 1', color: deepPurple },
  { id: 2, text: 'Stanza 2', color: indigo },
  { id: 3, text: 'Stanza 3', color: blue },
  { id: 4, text: 'Stanza 4', color: cyan },
  { id: 5, text: 'Stanza 5', color: teal },
];

