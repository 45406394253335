import React from 'react';
import { useState, useEffect } from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';

import { useStyles } from './AppointmentFormStyles.js';


function AppointmentForm({ company, listDoctors, submitAppointment }) {

    const classes = useStyles();

    const pageInfo = [
        {
            title: 'Prenota una visita',
            subtitle: 'Compila il form per richiedere un appuntamento',
        }
    ];

    const [doctorList, setDoctorList] = useState(null);

    useEffect(() => {
        (async() => {
            const doctorList = await listDoctors();
            setDoctorList(doctorList);
        })();
    }, [listDoctors]);   

    let doctorID = new URLSearchParams(window.location.search).get('doctor');

    if(doctorID) {
        doctorID = doctorID.replace(/(<([^>]+)>)/gi, "");
    } else {
        doctorID = '';
    }   

    const initialFormData = Object.freeze({
        patient_first_name: '',
        patient_last_name: '',
        patient_email: '',
        patient_phone: '',
        doctor: doctorID,
        message: '',
        send_intern_mail: 'on',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        if(String([e.target.name]) === 'doctor'){
            updateFormData({   
                ...formData,
                [e.target.name]: e.target.value,
            });
        } else {    
            updateFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
            });
        }   
    };

    const [formResponse, setFormResponse] = useState(false);
    const [formError, setFormError] = useState({
        patient_email: false,
        patient_phone: false,
        message: false,
    });

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Check Errors
        if(formData.patient_email === '') {
            setFormError({
                patient_email: true,
                patient_phone: false,
                message: false,
            });
            return;
        }
        if(formData.patient_phone === '') {
            setFormError({
                patient_email: false,
                patient_phone: true,
                message: false,
            });
            return;
        }
        if(formData.message === '') {
            setFormError({
                patient_email: false,
                patient_phone: false,
                message: true,
            });
            return;
        }
        setFormError(false);

        const submitForm = await submitAppointment(formData);
        //console.log(submitForm);
        setFormResponse(submitForm.success);
    };

    if (!doctorList || doctorList.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <>
        <PageHeader pageInfo={pageInfo} />
        <Container maxWidth="xl">
            <Grid container className={classes.appointmentFormGrid}>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} className={classes.formWrapper}>
                        <Typography variant="h5" className={classes.formTitle} mb={2}>
                            Prenota una visita
                        </Typography>
                        {(formResponse === 'Sent') 
                            ? <p><Alert severity="success" className={classes.formResponse}>Il messaggio è stato inviato correttamente.</Alert></p>
                            :
                            <form className={classes.form}>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="patient_first_name"
                                            label="Nome"
                                            name="patient_first_name"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formResponse && formResponse.patient_first_name && 'Inerisci nome'}   
                                            error={formResponse && formResponse.patient_first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="patient_last_name"
                                            label="Cognome"
                                            name="patient_last_name"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formResponse && formResponse.patient_last_name && 'Inerisci cognome'}   
                                            error={formResponse && formResponse.patient_last_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="patient_email"
                                            label="Indirizzo Email"
                                            name="patient_email"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={(formResponse.patient_email || formError.patient_email) && 'Indirizzo email non valido'}   
                                            error={formResponse.patient_email || formError.patient_email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="patient_phone"
                                            label="Telefono"
                                            name="patient_phone"
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.patient_phone && 'Inserisci telefono'}   
                                            error={formError.patient_phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="doctor-select-label">Specialista</InputLabel>
                                            <Select
                                                required
                                                labelId="doctor-select-label"
                                                id="doctor-select"
                                                label="Specialista"
                                                name="doctor"
                                                onChange={handleChange}
                                                value={formData.doctor}
                                                className={classes.inputField}
                                                error={formResponse && formResponse.doctor}
                                            >
                                            {doctorList.map((doctor) => {
                                                return(
                                                    <MenuItem value={doctor.id} key={doctor.id}>{doctor.last_name} {doctor.first_name}</MenuItem>
                                                );
                                            })}
                                            </Select>
                                        </FormControl>
                                        {formResponse && formResponse.doctor && <FormHelperText className={classes.error}>Selezionare specialista</FormHelperText>}   
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="message"
                                            name="message"
                                            label="Messaggio"
                                            multiline
                                            rows={10}
                                            onChange={handleChange}
                                            className={classes.inputField}
                                            helperText={formError.message && 'Inserisci un messaggio'}   
                                            error={formError.message}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                >
                                    Invia
                                </Button>
                            </form>
                        }                       
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ padding: '50px' }}>
                        <img src={company.logo} width="120" alt={company.name} />
                        <Typography variant="h2" className={classes.companyInfoTitle}>
                            {company.name}
                        </Typography>
                        <Typography variant="h4" component="h3" mb={5} className={classes.companyInfoSubTitle}>
                            {company.slogan}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </>
    );
}

export default AppointmentForm;
