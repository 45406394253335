import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardMainWrapper: {
        marginTop: '80px',
    },
    dashboardTitle: {
        fontFamily: "'Poppins', sans-serif !important",
        fontWeight: '600',
        color: '#1e375f',
    },
    dashboardSubTitle: {
        fontFamily: "'Poppins', sans-serif !important",
        color: '#6f97ff',
    },
    manageAppointment: {
        width: '100%',
        backgroundColor: '#1e375f',
        color: '#fff',
        border: '1px solid #212121',
        padding: '5px 15px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#30538c',
            color: '#fff',
        },
    },
}));

export { useStyles };