import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formTitle: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
        fontWeight: '600',
    },
    formResponse: {
        fontFamily: "'Poppins', sans-serif",
        marginTop: '20px',
    },
    inputField: {
        backgroundColor: '#fff',
    },
    appointmentWrapper: {
        marginTop: '30px',
    },
    dashboardMainWrapper: {
        marginTop: '80px',
    },
    appointmentTitle: {
        color: '#1e375f',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '10px',
    },
    checkboxLabel: {
        '& span': {
            fontFamily: "'Poppins', sans-serif !important",
        },
    },
    error: {
        color: '#d32f2f',
    }
}));

export { useStyles };