import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useContext } from 'react';
import { ApiContext } from '../../API/API';
import Dashboard from '../../../views/Admin/Dashboard/Dashboard';
import CssBaseline from '@mui/material/CssBaseline';
import ScrollToTop from '../../../utils/ScrollToTop';
import Box from '@mui/material/Box';

function AdminRoutes() {

    const api = useContext(ApiContext); 

    return (
        <Router>
            <ScrollToTop />
            <CssBaseline />  
            <Box sx={{ flexGrow: 1, height: '100%' }}>
                <Dashboard 
                    userInfo={api.userInfo}
                    company={api.company} 
                    user={api.user} 
                    logout={api.logout} 
                    adminLinks={api.adminLinks}
                    doctorLinks={api.doctorLinks}
                    appointmentsList={api.appointmentsList}
                    appointmentDetails={api.appointmentDetails}
                    editAppointment={api.editAppointment}
                    listDoctors={api.listDoctors}
                    submitAppointment={api.submitAppointment}
                    listRooms={api.listRooms}
                    deleteAppointment={api.deleteAppointment}
                />
            </Box>           
        </Router>
    );

}

export default AdminRoutes;