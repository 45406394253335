import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dashboardAppBar: {
        backgroundColor: '#dbeaf9',
    },
    dashboardSideBar: {
        backgroundColor: '#dbeaf9 !important',
    },
    dashboardHamburger: {
        color: '#1e375f', 
        display: 'none !important',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block !important',
        },
    },
    homeLink: {
        color: '#1e375f',
        textDecoration: 'none',
        fontFamily: '"Poppins", sans-serif',
        marginLeft: '10px',
        fontSize: '.875rem',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    sidebarTitle: {
        padding: '10px 20px',
        textAlign: 'center',
        fontFamily: '"Poppins", sans-serif',
        color: '#1e375f',
        fontSize: '1.2rem',
        fontWeight: '600',
    },
    adminLinkList: {
        padding: '8px 16px',
    },
    adminLink: {
        width: '100%',
        padding: '5px 15px',
    },
    adminLinkIcon: {
        verticalAlign: 'middle',
        color: '#1e375f',
    },
    adminLinkTitle: {
        color: '#1e375f',
        fontFamily: '"Poppins", sans-serif',
        marginLeft: '10px',
    },
    siteImg: {
        verticalAlign: 'middle',
    },
    siteTitle: {
        color: '#1e375f',
        fontFamily: '"Poppins", sans-serif',
        marginLeft: '10px',
    },
    loginButton: {
        color: '#1e375f',
        border: '1px solid #212121',
        padding: '5px 15px',
        borderRadius: '4px',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
        '&:hover': {
            backgroundColor: '#1e375f',
            color: '#fff',
            border: '1px solid rgb(255 255 255)',
        },
    },   
    dashboardButton: {
        color: '#1e375f',
        marginRight: '5px',
        border: '1px solid #212121',
        boxShadow: 'none',
        padding: '5px 15px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#1e375f',
            color: '#fff',
            border: '1px solid rgb(255 255 255)',
        },
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    loggedMessage: {
        fontFamily: '"Poppins", sans-serif',
        marginRight: '5px',
        color: '#1e375f',
        fontSize: '.875rem',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
}));

export { useStyles };