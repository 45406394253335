import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useStyles } from './BreadcrumbsBarStyles.js';

const BreadcrumbsBar = () => {
  const breadcrumbs = useBreadcrumbs();

  const classes = useStyles();

  return (
    <React.Fragment>
        <Container maxWidth="xl" className={classes.breadcrumbsWrapper}>
            <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs.map(({ match, breadcrumb }) => (                              
            <Link 
                component={NavLink}
                to={match.url}
                underline="none"
                className={classes.breadcrumbsLink}
                key={breadcrumb}
            >
                {breadcrumb.props.children.replace('-', ' ')}
            </Link>            
            ))}
            </Breadcrumbs>
        </Container>
    </React.Fragment>
  );
}

export default BreadcrumbsBar;

