import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        backgroundColor: '#3c70fa',
        color: '#fff',
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    footerSiteTitle: {
        fontSize: '2rem',
        fontFamily: "'Poppins', sans-serif",
    },
    footerSiteSubTitle: {
        fontSize: '1.2rem',
        fontFamily: "'Poppins', sans-serif",
    },
    footerSiteDescription: {
        marginTop: '20px',
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
    },
    footerCompanyInfo: {
        marginTop: '15px',
        fontSize: '1rem',
        fontFamily: "'Poppins', sans-serif",
    },
    footerList: {
        listStyle: 'none',
        fontFamily: "'Poppins', sans-serif",
    },
    footerListItem: {
        marginBottom: '10px',
    },
    footerLinks: {
        color: '#fff',
        fontFamily: "'Poppins', sans-serif",
    },
    actalogo: {
        verticalAlign: 'bottom',
    },
    cookieLink: {
        color: '#799cfa',
        textDecoration: 'none',
    },
    footerHoursTitle: {
        marginBottom: '10px',
        fontFamily: "'Poppins', sans-serif",
    },
    footerHours: {
        marginBottom: '0',
        marginTop: '0',
        fontFamily: "'Poppins', sans-serif",
    }
}));

export { useStyles };