import React from 'react';
import FullPageImg from '../../components/FullPageImg/FullPageImg';
import HomeContent from '../../components/HomeContent/HomeContent';
import Departments from '../../components/Departments/Departments';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useStyles } from './HomePageStyles.js'


function HomePage({ listDepartments, company, actionLinks }) {

    const classes = useStyles();

    return (
    <>     
        <FullPageImg company={company} actionLinks={actionLinks} />
        <Container maxWidth="xl" component="main" className={classes.departmentsWrapper}>
            <Typography variant="h2" className={classes.departmentsTitle}>
                I nostri servizi
            </Typography>
        </Container>
        <Departments listDepartments={listDepartments} />
        <HomeContent listDepartments={listDepartments} />            
    </>
    );
}

export default HomePage;