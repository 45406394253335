import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useStyles } from './FullPageImgStyles.js'

function FullPageImg({ company, actionLinks }) {

    const classes = useStyles();

    return (
        <>
        <Box className={classes.homepageBackground}>
            <Box className={classes.homepageOverlay}>                   
                <Container maxWidth="xl">
                    <Box className={classes.siteInfoWrapper}>
                        <img src={company.logo_alt} width="50" className={classes.siteLogo} title={company.name} alt={company.name} />
                        <Typography variant="h1" className={classes.siteTitle}>
                            {company.name}
                        </Typography>
                        <Typography variant="h2" className={classes.siteSubTitle}>
                            {company.slogan}
                        </Typography>
                        <Button 
                            variant="outlined"
                            className={classes.actionButton}
                            component={NavLink}
                            to={actionLinks[0].link}
                            title={actionLinks[0].title}
                        >
                            {actionLinks[0].title}
                        </Button>
                    </Box>
                    <Grid container spacing={2} className={classes.homeActionWrapper}>
                        <Grid item xs={12} md={6}>
                            <Link color="inherit" className={classes.homeActionLink} component={NavLink} to={actionLinks[1].link} title={actionLinks[1].title}>
                                <Paper elevation={2} className={classes.homeActionButton}>                                
                                    {actionLinks[1].title}                                
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Link color="inherit" className={classes.homeActionLink} component={NavLink} to={actionLinks[2].link} title={actionLinks[2].title}>
                                <Paper elevation={2} className={classes.homeActionButton}>                                
                                    {actionLinks[2].title}                              
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
        </>
    );
}

export default FullPageImg;

