import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
import useLocalStorage from '../../utils/useLocalStorage';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FacebookIcon from '@mui/icons-material/Facebook';
import TodayIcon from '@mui/icons-material/Today';

const ApiContext = createContext({});

export default function API({ children }) {

    const baseURL = process.env.REACT_APP_API_URL;

    const [accessToken, setAccessToken] = useLocalStorage('access_token');
    const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token');
    const [user, setUser] = useLocalStorage('user');


    // Header Links

    const headerLinks = [
        {
            title: 'HOME',
            link: '/',
        },
        {
            title: 'CHI SIAMO',
            link: '/chi-siamo',
        },
        {
            title: 'SERVIZI',
            link: '/servizi',
        },
        {
            title: 'SPECIALISTI',
            link: '/specialisti',
        },
        {
            title: 'PRENOTA UNA VISITA',
            link: '/prenota-una-visita',
        },
        {
            title: 'CONTATTI',
            link: '/contatti',
        },
    ];

    // Social Links

    const socialLinks = [
        {
            title: 'Facebook',
            link: 'https://www.facebook.com/Studio-Medico-Ippocrates-1902108073400236/',
            icon: <FacebookIcon />,
        },
    ];

    // Action Links

    const actionLinks = [
        {
            title: 'RICHIEDI UN APPUNTAMENTO',
            link: '/prenota-una-visita',
        },
        {
            title: 'CERCA UNO SPECIALISTA',
            link: '/specialisti',
        },
        {
            title: 'PRENOTA UNA VISITA',
            link: '/prenota-una-visita',
        },
    ];

    // Footer Links

    const footerLinks = [
        {
            title: 'Chi Siamo',
            link: '/chi-siamo',
        },
        {
            title: 'Contatti',
            link: '/contatti',
        },
        {
            title: 'Privacy Policy',
            link: '/privacy-policy',
        },
    ];

    // Admin Links

    const adminLinks = [
        {
            title: 'Calendario',
            link: '/admin',
            icon: <TodayIcon />,
        },
        {
            title: 'In attesa',
            link: '/admin/elenco-appuntamenti/stato/in-attesa',
            icon: <PendingActionsIcon />,
        },
        {
            title: 'Confermati',
            link: '/admin/elenco-appuntamenti/stato/confermati',
            icon: <CheckCircleOutlineIcon />,
        },
        {
            title: 'Chiusi',
            link: '/admin/elenco-appuntamenti/stato/chiusi',
            icon: <FolderOpenIcon />,
        },
    ];

    // Doctor-only Links

    const doctorLinks = [
        {
            title: 'Calendario',
            link: '/admin',
            icon: <TodayIcon />,
        },
    ]

const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
            Authorization: accessToken ? 'JWT ' + accessToken : null,
            'Content-Type': 'application/json',
            accept: 'application/json',
        }, 
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;

            if (typeof error.response === 'undefined') {
                alert(
                    'A server/network error occurred. ' +
                    'Looks like CORS might be the problem. ' +
                    'Sorry about this - we will get it fixed shortly.'
                );
                return Promise.reject(error);
            }

            if (
                error.response.status === 401 &&
                originalRequest.url === baseURL + 'token/refresh/'
            ) {
                window.location.href = '/login/';
                return Promise.reject(error);
            }

            if (
                error.response.data.code === 'token_not_valid' &&
                error.response.status === 401 &&
                error.response.statusText === 'Unauthorized' && 
                !originalRequest._retry
            ) {
                // Clear tokens and logout the user
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user');
                window.location.href = '/login/';
            }

            // specific error handling done elsewhere
            return Promise.reject(error);
        }
    );

    return instance;
}

    const [axiosInstance, setAxiosInstance] = useState(createAxiosInstance);

    useEffect(() => {
        setAxiosInstance(createAxiosInstance);
    }, [accessToken, refreshToken]);

    // Login
    const login = async(formData) => {

        const { data } = await axiosInstance.post('token/', {...formData });

        if(data) {
            setAccessToken(data.access);
            setRefreshToken(data.refresh);
            setUser(formData.username);
            axiosInstance.defaults.headers['Authorization'] = 'JWT ' + accessToken;
        }

        return data;
    }

    // Logout
    const logout = async() => {

        await axiosInstance.post('user/logout/blacklist/', { refresh_token: refreshToken });
        
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        axiosInstance.defaults.headers['Authorization'] = null;         

        window.location.href = '/login/';
    }

    // Get User Info
    const [userInfo, setuserInfo] = useState({});

    useEffect(() => {     
        axiosInstance.get('user/info/').then((res) => {
            const userInfo = res.data[0];
            setuserInfo({ ...userInfo });
        });               
    }, []);

    // Get Company Info
    const [company, setCompany] = useState({});

    useEffect(() => {     
        axiosInstance.get('company/info/').then((res) => {
            const companyInfo = res.data[0];
            setCompany({ ...companyInfo });
        });               
    }, []);

    // Get Departments
    const listDepartments = async() => {
        const { data } = await axiosInstance.get('departments');
        return data;
    }

    // Get Doctors
    const listDoctors = async() => {
        const { data } = await axiosInstance.get('doctors');
        return data;
    }

    // Get Doctor Profile
    const doctorProfile = async(slug) => {
        const { data } = await axiosInstance.get('doctors/' + slug);
        return data;
    }

    // Get Service Page
    const servicePage = async(slug) => {
        const { data } = await axiosInstance.get('departments/' + slug);
        return data;
    }

    // Get Doctors for each Department
    const doctorsDepartment = async(department) => {
        const { data } = await axiosInstance.get('doctors/department/' + department);
        return data;
    }

    // Get Rooms
    const listRooms = async() => {
        const { data } = await axiosInstance.get('rooms');
        return data;
    }

    // Submit Appointment Form
    const submitAppointment = async(formData) => {
        const { data } = await axiosInstance.post('appointments/', {...formData });
        return data;
    }

    // Appointments List 
    const appointmentsList = async(status) => {
        if(status === 'in-attesa'){
            status = 'waiting';
        } else if(status === 'confermati'){
            status = 'confirmed';
        } else {
            status = 'closed';
        }
        const { data } = await axiosInstance.get('appointments/status/' +  status );
        return data;
    }

    // Appointment Details
    const appointmentDetails = async(appointment) => {
        const { data } = await axiosInstance.get('appointments/' +  appointment );
        return data;
    }

    // Edit Appointment
    const editAppointment = async(appointment, formData) => {
        const { data } = await axiosInstance.patch('appointments/' +  appointment + '/', {...formData} );
        return data;
    }

    // Delete Appointment
    const deleteAppointment = async(appointment) => {
        const { data } = await axiosInstance.delete('appointments/' +  appointment + '/');
        return data;
    }

    const api = {
        headerLinks,
        socialLinks,
        actionLinks,
        footerLinks,
        adminLinks,
        doctorLinks,
        userInfo,
        company,
        listDepartments,
        listDoctors,
        listRooms,
        doctorProfile,
        servicePage,    
        doctorsDepartment,
        submitAppointment,
        appointmentsList,
        appointmentDetails,
        editAppointment,
        deleteAppointment,
        login,
        logout,
        isAuth: !!user,
        user,
    }

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export { ApiContext };