import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { useStyles } from './AppointmentDetailsStyles.js';


function AppointmentDetails({ userInfo, appointmentDetails }) {

    const classes = useStyles();

    const { appointmentID } = useParams();

    const [appointment, setAppointment] = useState(null);
    
    useEffect(() => {
        (async() => {
            const appointment = await appointmentDetails(appointmentID);
            setAppointment(appointment);
        })();
    }, [appointmentDetails, appointmentID]);

    let actionResult = new URLSearchParams(window.location.search).get('action');

    useEffect(() => {
        if(actionResult) {
            actionResult = actionResult.replace(/(<([^>]+)>)/gi, "");
        } else {
            actionResult = '';
        }  
    });    

    let pageTitle = '';

    if(!appointment || appointment.length === 0) return <Box sx={{ minHeight: '100vh' }} />;

    if(appointment.status === 'waiting'){
        pageTitle = 'Richiesta in attesa';
    } else if(appointment.status === 'confirmed'){
        pageTitle = 'Appuntamento Confermato';
    } else if(appointment.status === 'closed'){
        pageTitle = 'Appuntamento Chiuso';
    }
    
    return(
        <>
        <Box>
            <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={10}>
                        <Typography className={classes.appointmentTitle}>
                            {pageTitle}
                        </Typography>
                    </Grid>
                    {(userInfo.groups && userInfo.groups.length === 0) &&
                        <Grid item xs={12} sm={12} md={2} sx={{ textAlign: 'right' }}>
                            <Button variant="contained" href={'/admin/elenco-appuntamenti/gestisci/' + appointment.id} className={classes.manageAppointment}>Gestisci</Button>
                        </Grid>
                    }
                </Grid>
                <Divider />
                {(actionResult === 'Sent') && <p><Alert severity="success" className={classes.actionResult}>Modifiche salvate. Il messaggio è stato inviato correttamente.</Alert></p>}
                {(actionResult === 'Saved') && <p><Alert severity="success" className={classes.actionResult}>Modifiche Salvate.</Alert></p>}
                {(actionResult === 'Failed') && <p><Alert severity="error" className={classes.actionResult}>Si è verificato un errore. Riprovare più tardi.</Alert></p>}                        
                <Box className={classes.appointmentWrapper}> 
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography className={classes.patientInfo}><strong>Data Richiesta:</strong> {appointment.dateFormat}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography className={classes.patientInfo}><strong>Paziente:</strong> {appointment.patient_last_name} {appointment.patient_first_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography className={classes.patientInfo}><strong>Email:</strong> {appointment.patient_email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Typography className={classes.patientInfo}><strong>Telefono:</strong> {appointment.patient_phone}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography className={classes.patientInfo}><strong>Specialista:</strong> {appointment.doctorLastName} {appointment.doctorFirstName}</Typography>                        
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography className={classes.patientInfo}><strong>Stato:</strong> {pageTitle}</Typography>                        
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.patientInfo}><strong>Messaggio del paziente:</strong> <br/><br/> {appointment.message}</Typography>
                        </Grid>
                    {(appointment.status === 'confirmed' || appointment.status === 'closed') &&
                        <>
                        <Grid item xs={12} md={4}>
                            <Typography className={classes.patientInfo}><strong>Data e Ora Appuntamento:</strong> {appointment.appointmentDateFormat}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography className={classes.patientInfo}><strong>Stanza:</strong> {appointment.room}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography className={classes.patientInfo}><strong>Durata:</strong> {appointment.duration} minuti</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.patientInfo}><strong>Note:</strong> <br/><br/> {appointment.notes}</Typography>
                        </Grid>
                        </>
                    }
                    </Grid>
                </Box>
            </Container>
        </Box>
        </>
    );
}

export default AppointmentDetails;