import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { NavLink } from 'react-router-dom';

import { useStyles } from './ServicePageStyles.js';

const DoctorsDepartment = ({ doctorsDepartment, serviceID }) => {

    const classes = useStyles();

    const [doctors, setDoctors] = useState(null);
    
    useEffect(() => {
        (async() => {
            const doctors = await doctorsDepartment(serviceID);
            setDoctors(doctors);
        })();
    }, [doctorsDepartment, serviceID]);

    if(!doctors || doctors.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Grid container>
        {doctors.map((doctor) => {
            return(
                <Grid item key={doctor.id} xs={12}>
                    <Card className={classes.doctorCard}>
                        <CardContent>
                            <Typography variant="h5" component="div" className={classes.doctorCardName}>
                               {doctor.title} {doctor.last_name} {doctor.first_name} 
                            </Typography>                                       
                        </CardContent>
                        <CardActions>
                            <Link
                                component={NavLink}
                                color="textPrimary"
                                to={'/specialisti/' + doctor.slug}
                                className={classes.doctorProfileLink}
                            >
                                <Button 
                                    size="small"                                   
                                >
                                    Visualizza profilo
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
            );
        })} 
            </Grid>
        </Box>
    );
};

const Service = ({ servicePage, doctorsDepartment }) => {

    const classes = useStyles();

    const { slug } = useParams();
    const [service, setService] = useState(null);
    
    useEffect(() => {
        (async() => {
            const service = await servicePage(slug);
            setService(service);
        })();
    }, [servicePage, slug]);

    if(!service || service.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <>
            <Box sx={{ textAlign: 'center' }} mb={5}>
                <img src={service.image} alt={service.name} title={service.name} className={classes.serviceImg} />
            </Box>
            <Box sx={{ padding: '20px' }}>
                <Typography variant="h2" className={classes.serviceName}>
                    {service.name}
                </Typography>
                <Typography variant="body2" className={classes.serviceDescription} mt={5}>
                    {service.description}
                </Typography>
            </Box>
            <Box sx={{ padding: '20px' }} mt={5}>
                <Typography variant="h3" className={classes.serviceSubTitle}>
                    Specialisti del settore
                </Typography>
            </Box>
            <DoctorsDepartment doctorsDepartment={doctorsDepartment} serviceID={service.id} />
        </>
    );
};

function ServicePage({ servicePage, doctorsDepartment }) {

    const pageInfo = [
        {
            title: 'Servizi',
            subtitle: 'Si effettuano visite mediche in numerose branche specialistiche',
        },
    ];

    return(
        <>
            <PageHeader pageInfo={pageInfo} />
            <Box>
                <Container maxWidth="lg">
                    <Service servicePage={servicePage} doctorsDepartment={doctorsDepartment} />
                </Container>
            </Box>
        </>
    );
};

export default ServicePage;