import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    privacyWrapper: {
        marginTop: '50px',
        fontSize: '1.2rem',
        fontFamily: '"Poppins", sans-serif',
        whiteSpace: 'pre-line',
        paddingLeft: '54px',
        paddingRight: '54px',
    },
}));

export { useStyles };