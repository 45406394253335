import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import itLocale from 'date-fns/locale/it';

import { useStyles } from './AddAppointmentStyles.js';


function AddAppointment({ listRooms, listDoctors, submitAppointment }) {

    const classes = useStyles();

    const history = useHistory();

    const [doctorList, setDoctorList] = useState(null);

    useEffect(() => {
        (async() => {
            const doctorList = await listDoctors();
            setDoctorList(doctorList);
        })();
    }, [listDoctors]);

    const [roomList, setRoomList] = useState(null);

    useEffect(() => {
        (async() => {
            const roomList = await listRooms();
            setRoomList(roomList);
        })();
    }, [listRooms]);

    const initialFormData = Object.freeze({
        appointment_date: new Date(),
        duration: '',
        patient_first_name: '',
        patient_last_name: '',
        patient_email: '',
        patient_phone: '',
        doctor: '',
        doctor_first_name: '',
        doctor_last_name: '',
        room: '',
        notes: '',
        status: 'confirmed',
        send_confirm: 'off',
        send_intern_mail: 'off',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e, value) => {
        if(String([e.target.name]) === 'doctor'){
            updateFormData({   
                ...formData,
                [e.target.name]: e.target.value,
                'doctor_last_name': value.props.children[0],
                'doctor_first_name': value.props.children[2],
            });
        } else if(String([e.target.name]) === 'room'){    
            updateFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        } else if(String([e.target.name]) === 'duration'){    
            updateFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        } else if(String([e.target.name]) === 'send_confirm'){
            if(formData.send_confirm === 'off') {
                updateFormData({
                    ...formData,
                    'send_confirm': 'on',
                });
            } else {
                updateFormData({
                    ...formData,
                    'send_confirm': 'off',
                });
            }            
        } else {    
            updateFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
            });
        }
    };

    const handleDateChange = (date) => {
        updateFormData({
            ...formData,
            appointment_date: date,
        });
    }

    const [formResponse, setFormResponse] = useState(false);
    const [formError, setFormError] = useState({
        duration: false,
        room: false,
    });

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Check Errors
        if(formData.duration === '') {
            setFormError({
                duration: true,
                room: false,
            });
            return;
        }
        if(formData.room === '') {
            setFormError({
                duration: false,
                room: true,
            });
            return;
        }
        setFormError(false);

        const submitForm = await submitAppointment(formData);

        if(submitForm.success === 'Sent' || submitForm.success === 'Saved') {
            history.push({
                pathname: '/admin/elenco-appuntamenti/stato/confermati/?action=' + submitForm.success,
            });
            window.location.reload();
        } else {
            setFormResponse(submitForm.success);
        }
    };

    if (!doctorList || doctorList.length === 0) return <Box sx={{ minHeight: '100vh' }} />;
    return(
        <>
        <Box>
            <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
                <Typography className={classes.appointmentTitle}>
                    Aggiungi Appuntamento
                </Typography>
                <Divider />
                <Box className={classes.appointmentWrapper}>            
                <form className={classes.form}>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                                <DateTimePicker
                                    label="Data e Ora *"
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    name="appointment_date"
                                    value={formData.appointment_date}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth >
                                <InputLabel id="duration-select-label" required >Durata</InputLabel>
                                <Select
                                    required
                                    labelId="duration-select-label"
                                    id="duration-select"
                                    label="Durata"
                                    name="duration"
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    value={formData.duration}
                                    error={formError.duration}
                                >
                                    <MenuItem key="15" value="15">15 Minuti</MenuItem>
                                    <MenuItem key="20" value="20">20 Minuti</MenuItem>
                                    <MenuItem key="30" value="30">30 Minuti</MenuItem>
                                    <MenuItem key="40" value="40">40 Minuti</MenuItem>
                                </Select>
                            </FormControl> 
                            {formError.duration && <FormHelperText className={classes.error}>Selezionare una durata</FormHelperText>}   
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="room-select-label" required >Stanza</InputLabel>
                                <Select
                                    required
                                    labelId="room-select-label"
                                    id="room-select"
                                    label="Stanza"
                                    name="room"
                                    onChange={handleChange}
                                    className={classes.inputField}
                                    value={formData.room}
                                    error={formError.room}
                                >
                                {roomList && roomList.map((room) => {
                                    return(
                                        <MenuItem key={room.id} value={room.id} >{room.name}</MenuItem>
                                    );
                                })}
                                </Select>
                            </FormControl>
                            {formError.room && <FormHelperText className={classes.error}>Selezionare una stanza</FormHelperText>}   
                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <TextField 
                                variant="outlined"
                                required
                                fullWidth
                                id="patient_first_name"
                                label="Nome"
                                name="patient_first_name"
                                onChange={handleChange}
                                className={classes.inputField}
                                helperText={formResponse && formResponse.patient_first_name && 'Il campo Nome Paziente non può essere vuoto'}   
                                error={formResponse && formResponse.patient_first_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                variant="outlined"
                                required
                                fullWidth
                                id="patient_last_name"
                                label="Cognome"
                                name="patient_last_name"
                                onChange={handleChange}
                                className={classes.inputField}
                                helperText={formResponse && formResponse.patient_last_name && 'Il campo Cognome Paziente non può essere vuoto'}   
                                error={formResponse && formResponse.patient_last_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                id="patient_email"
                                label="Indirizzo Email"
                                name="patient_email"
                                onChange={handleChange}
                                className={classes.inputField}
                                helperText={formResponse && formResponse.patient_email && 'Indirizzo email non valido'}   
                                error={formResponse && formResponse.patient_email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                id="patient_phone"
                                label="Telefono"
                                name="patient_phone"
                                onChange={handleChange}
                                className={classes.inputField}
                                value={formData.patient_phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="doctor-select-label" required>Specialista</InputLabel>
                                <Select
                                    required
                                    labelId="doctor-select-label"
                                    id="doctor-select"
                                    label="Specialista"
                                    name="doctor"
                                    onChange={handleChange}
                                    value={formData.doctor}
                                    className={classes.inputField}
                                    error={formResponse && formResponse.doctor}
                                >
                                {doctorList && doctorList.map((doctor) => {
                                    return(
                                        <MenuItem key={doctor.id} value={doctor.id}>{doctor.last_name} {doctor.first_name}</MenuItem>
                                    );
                                })}
                                </Select>
                            </FormControl>
                            {formResponse && formResponse.doctor && <FormHelperText className={classes.error}>Selezionare specialista</FormHelperText>}   
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="notes"
                                name="notes"
                                label="Note"
                                multiline
                                rows={10}
                                onChange={handleChange}
                                className={classes.inputField}
                            />
                        </Grid>
                        <Grid item xs={12} mb={5}>
                            <FormControlLabel
                                control={<Checkbox value={formData.send_confirm} name="send_confirm" color="primary" onChange={handleChange} />}
                                label="Invia email di conferma al paziente"
                                className={classes.checkboxLabel}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Aggiungi
                    </Button>
                </form>
                {formResponse && (typeof formResponse === 'string') && formResponse.includes('UNIQUE constraint failed') &&
                    <Alert severity="error" className={classes.formResponse}>
                        <p>Esiste già un appuntamento per questa data</p>
                    </Alert>
                }                
                </Box>
            </Container>
        </Box>
        </>
    );
}

export default AddAppointment;