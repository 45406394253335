import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    departmentSideBar: {
        backgroundColor: '#edf2ff',
        paddingLeft: '10px', 
        paddingRight: '10px',
    },
    departmentsTitle: {
        fontSize: '1.3rem',
        fontFamily: "'Poppins', sans-serif",
        paddingTop: '20px',
        paddingLeft: '15px',
        color: '#656c7e',
        fontWeight: '600',
        marginBottom: '20px',
        
    },
    departmentListItem: {
        borderBottom: '1px solid #ebebeb',        
    },
    departmentsLink: {
        textDecoration: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.2rem',
        color: '#7397ff',
        '&:hover': {
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '.875rem',
        },
    },
    doctorCard: {
        backgroundColor: '#edf2ff',
        marginBottom: '20px',
    },
    doctorCardName: {
        fontFamily: "'Poppins', sans-serif",
        color: '#1e375f',
    },
    doctorCardSubName: {
        fontFamily: "'Poppins', sans-serif",
        marginTop: '20px',
        fontSize: '1.2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    doctorCardDepartments: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '.8751rem',
        },
    },
    doctorProfileLink: {
        textDecoration: 'none',
    },
}));

export { useStyles };