import React from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useStyles } from './PrivacyStyles.js';

function Privacy() {

    const classes = useStyles();

     const pageInfo = [
        {
            title: 'Privacy Policy',
            subtitle: 'INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI - Art. 13 GDPR 2016/679',
        },
    ]; 

    return(
        <>
            <PageHeader pageInfo={pageInfo} />
            <Box>
                <Container maxWidth="xl" className={classes.privacyWrapper}>
                    <p>
                        L'informativa è resa ai sensi degli articoli 13 e ss. del Regolamento UE n.
                        2016/679 relativo alla protezione dei dati personali delle persone fisiche
                        che interagiscono con i servizi web dell'Ente, accessibili dalla pagina
                        iniziale del sito ufficiale.
                        <br/>
                        <br/>
                        <strong>1. Trattamento dei dati personali</strong>
                        <br/>
                        Con riferimento ai dati trattati, l'Ente informa che:
                        <br/>
                        - il trattamento dei dati personali è finalizzato all'esecuzione di compiti
                        di interesse pubblico o comunque connessi all'esercizio dei poteri
                        pubblici, di competenza dell'Ente in base a norme di leggi, Statuto e
                        regolamenti;
                        <br/>
                        - il trattamento dei dati particolari è effettuato in base a norme di
                        legge, Statuto e regolamenti per motivi di interesse pubblico rilevante;
                        <br/>
                        - il trattamento è effettuato con strumenti telematici e/o manuali;
                        <br/>
                        - il conferimento dei dati è facoltativo, ma necessario per il corretto
                        svolgimento dell'istruttoria e degli adempimenti procedimentali o per
                        l’erogazione del servizio;
                        <br/>
                        - il mancato conferimento di alcuni o di tutti i dati richiesti può
                        comportare l'interruzione del procedimento o del servizio;
                        <br/>
                        - in relazione al procedimento e alle attività correlate, l'Ente può
                        comunicare i dati acquisiti ad altri Enti pubblici o privati competenti,
                        nei casi previsti da norme di legge, Statuto, regolamenti;
                        <br/>
                        - i dati saranno trattati dal Titolare del trattamento, suoi dipendenti e
                        collaboratori, cui sono comunicate idonee istruzioni o da imprese
                        espressamente nominate come responsabili del trattamento; tali soggetti
                        assicurano livelli di esperienza, capacità e affidabilità tali da garantire
                        il rispetto delle vigenti disposizioni in materia di trattamento, compresa
                        la sicurezza dei dati;
                        <br/>
                        - i dati saranno conservati per un periodo non superiore a quello previsto
                        dalla base normativa che legittima il trattamento e in conformità alle
                        norme sulla conservazione della documentazione amministrativa; a tal fine,
                        anche mediante controlli periodici, viene verificata la stretta pertinenza,
                        non eccedenza e indispensabilità dei dati trattati;
                        <br/>
                        - i dati trattati sono soggetti alla normativa sul diritto di accesso, con
                        le modalità e i limiti di cui alle leggi vigenti.
                        <br/>
                        <br/>
                        <strong>2. Diritti dell'Interessato</strong>
                        <br/>
                        Gli interessati hanno diritto di chiedere all'Ente l’accesso ai dati
                        personali, la rettifica o la cancellazione degli stessi o la limitazione
                        del trattamento che li riguarda o di opporsi al trattamento (art. 15 ss.
                        Regolamento UE 2016/679).
                        <br/>
                        L’istanza è presentata contattando il Titolare del Trattamento.
                        <br/>
                        Hanno, altresì, diritto di proporre reclamo al Garante per la protezione
                        dei dati personali, quale autorità di controllo e diritto di ricorso
                        all'autorità giudiziaria.
                    </p>
                    <p>
                        <strong>
                            <br/>
                            INFORMATIVA PRIVACY PER COMUNICAZIONI ISTITUZIONALI VIA E-MAIL
                        </strong>
                    </p>
                    <p>
                        Ai sensi dell’art. 13 del Regolamento UE n. 2016/679 relativo alla
                        protezione delle persone fisiche con riguardo al Trattamento dei Dati
                        Personali, nonché alla libera circolazione di tali dati, si informano gli
                        interessati che il trattamento dei dati forniti direttamente tramite
                        portale web al fine di fruire dei servizi offerti o comunque acquisiti a
                        tal fine, è effettuato dall'Ente, in qualità di Titolare del trattamento,
                        ed è finalizzato unicamente all'espletamento delle attività connesse alla
                        gestione delle comunicazioni istituzionali che includono l’invio di
                        messaggi via e-mail agli indirizzi di posta elettronica forniti in sede di
                        registrazione/iscrizione al portale.
                        <br/>
                        Il trattamento dei dati personali sarà pertanto effettuato anche con
                        l’utilizzo di procedure automatizzate nei modi e nei limiti necessari per
                        perseguire le predette finalità garantendo la sicurezza e la riservatezza
                        dei dati stessi.
                        <br/>
                        I dati personali forniti in sede di registrazione/iscrizione al portale
                        (nome, cognome, residenza, riferimenti telefonici, indirizzo e-mail, ecc.)
                        sono utilizzati dai servizi di comunicazione di posta elettronica.
                        <br/>
                        Il conferimento di dati è obbligatorio e l’eventuale rifiuto preclude la
                        possibilità di fruire dei servizi dell'Ente. I predetti dati possono essere
                        utilizzati anche per l’invio di comunicazioni istituzionali non
                        strettamente connesse al servizio relativo all'iscrizione al portale ma
                        compatibili con le finalità istituzionali. Agli interessati è comunque
                        riservato il diritto di chiedere in qualsiasi momento la cancellazione dai
                        servizi di comunicazione per posta elettronica.
                        <br/>
                        I trattamenti saranno effettuati dalle persone fisiche preposte alla
                        relativa procedura e designate come soggetti autorizzati al trattamento. I
                        dati raccolti non sono oggetto di comunicazione a terzi, fatta salva la
                        possibilità che i dati siano conoscibili da soggetti che intervengono nella
                        procedura di gestione delle attività opportunamente individuati ed
                        eventualmente designati come Responsabili del trattamento.
                        <br/>
                        Ai soggetti interessati sono riconosciuti i diritti di cui all’art. 15 del
                        citato Codice, in particolare, il diritto di accedere ai dati personali che
                        li riguardano, di chiederne la rettifica, l’aggiornamento e la
                        cancellazione, se incompleti, erronei o raccolti in violazione di legge,
                        nonché di opporsi al loro trattamento per motivi legittimi rivolgendo la
                        richiesta all'Ente, come Titolare.
                    </p>
                    <p>
                        <strong>
                            <br/>
                            INFORMATIVA PRIVACY PER L'UTILIZZO DEI COOKIE
                        </strong>
                    </p>
                    <p>
                        Il sito utilizza cookie per migliorare l'esperienza di uso delle
                        applicazioni online. Il presente documento fornisce informazioni
                        dettagliate sull'uso dei cookie, su come sono utilizzati dall'Ente e su
                        come gestirli.
                        <br/>
                        <br/>
                        <strong>Cosa sono i cookie</strong>
                        <br/>
                        Questo sito utilizza i cookie, piccoli file di testo che vengono
                        memorizzati dal browser sul dispositivo dell’utente (computer, tablet,
                        smartphone) per migliorare l’esperienza di fruizione del sito.
                        <br/>
                        L’informazione contenuta nei cookie è definita lato server e, a seconda del
                        tipo di cookie, può essere usata ogni volta che il sito viene visitato
                        dall’utente.
                        <br/>
                        I cookie sono largamente utilizzati perché necessari ad alcune funzionalità
                        del sito, così come per raccogliere dati statistici sul traffico e
                        l’utilizzo del sito stesso.
                        <br/>
                        Sono pensati anche per ricordare operazioni che l’utente ha già fatto in
                        passato sul sito, ad esempio operazioni di login o click. Permettono di
                        risparmiare tempo quando un sito viene visitato più volte.
                        <br/>
                        <br/>
                        <strong>I cookie possono essere utilizzati:</strong>
                    </p>
                    <ul>
                        <li>
                            <p>
                                per ricordare le preferenze
                            </p>
                        </li>
                        <li>
                            <p>
                                per ricordare la password
                            </p>
                        </li>
                        <li>
                            <p>
                                per permettere di condividere pagine e contenuti sui social network
                            </p>
                        </li>
                        <li>
                            <p>
                                per permettere a chi gestisce il sito di avere informazioni
                                statistiche, come il numero di visitatori, la provenienza
                                geografica, le pagine più visitate, i browser oppure i dispositivi
                                utilizzati.
                            </p>
                        </li>
                    </ul>
                    <p>
                        I cookie non sono pericolosi, non sono programmi eseguibili e non possono
                        essere veicolo di virus. Non possono in alcun modo identificare
                        personalmente l'utente.
                        <br/>
                        <br/>
                        <strong>Tipologie di cookie utilizzate</strong>
                        <br/>
                        Il sito dell'Ente utilizza due tipologie di cookie:
                    </p>
                    <ul>
                        <li>
                            <p>
                                cookie di sessione
                            </p>
                        </li>
                        <li>
                            <p>
                                cookie analitici
                            </p>
                        </li>
                    </ul>
                    <p>
                        <br/>
                        <strong>Cookie di sessione</strong>
                        <br/>
                        I cookie di sessione sono indispensabili per gestire l’autenticazione ai
                        servizi online, o altre aree riservate.
                        <br/>
                        Questi vengono memorizzati sul computer dell’utente esclusivamente per la
                        durata della sessione e vengono cancellati automaticamente dal computer
                        quando il browser viene chiuso.
                        <br/>
                        I cookie di sessione permettono di navigare da una pagina all'altra senza
                        che l’utente sia costretto ad autenticarsi ripetutamente.
                        <br/>
                        <br/>
                        <strong>Cookie analitici</strong>
                        <br/>
                        A fini statistici utilizziamo Google Analytics, servizio di Google che non
                        permette di memorizzare dati personali. Le informazioni appartengono a
                        Google, sono depositate presso i loro server e consultabili da chi gestisce
                        il sito.
                        <br/>
                        I cookie analitici di Google hanno una durata variabile, alcuni sono legati
                        alla sessione, altri durano minuti, altri ancora dai sei mesi ai due anni.
                        <br/>
                        È Google che traccia ed esamina l’utilizzo del sito web e permette ai
                        gestori del sito di compilare report statistici.
                        <br/>
                        È possibile consultare i termini di servizio di Google Analytics
                        <br/>
                        I cookie analitici possono essere disabilitati dall'utente, senza nessuna
                        conseguenza sulla fruibilità del sito.
                        <br/>
                        È possibile disabilitare i cookie di Google Analytics, utilizzando il
                        componente aggiuntivo di opt-out fornito da Google per i browser
                        principali.
                        <br/>
                        <br/>
                        <strong>Altri cookie di terze parti</strong>
                        <br/>
                        Sul sito sono presenti pulsanti che permettono di condividere i contenuti
                        su vari social network, come Facebook, Twitter, Googleplus. Il clic sul
                        pulsante di condivisione potrebbe far sì che tali compagnie memorizzino dei
                        cookie sul computer.
                        <br/>
                        Sul sito sono anche presenti video incorporati da YouTube. Il clic sul
                        video player di YouTube potrebbe memorizzare dei cookie sul computer
                        dell’utente, ma l'Ente si impegna per il futuro a utilizzare l’opzione di
                        incorporamento “modalità di privacy avanzata”, che assicura che YouTube non
                        memorizzerà informazioni sugli utenti del sito, a meno che il video non
                        venga visualizzato.
                    </p>
                    <ul>
                        <li>
                            <p>
                                Informativa sulla privacy di Facebook
                            </p>
                        </li>
                        <li>
                            <p>
                                Informativa sulla privacy di Twitter
                            </p>
                        </li>
                        <li>
                            <p>
                                Informativa sulla privacy di Google/Youtube
                            </p>
                        </li>
                    </ul>
                    <p>
                        <br/>
                        <strong>Disabilitare i cookie</strong>
                        <br/>
                        Il consenso all’utilizzo dei cookie può essere negato attraverso le
                        impostazioni del browser. La fruizione del sito sarà comunque garantita, se
                        non autenticati.
                        <br/>
                        Procedura sulla disabilitazione dei cookie nei singoli browser:
                        <br/>
                        <br/>
                        <strong>Internet Exploer</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                cliccare su strumenti
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su opzioni internet
                            </p>
                        </li>
                        <li>
                            <p>
                                scegliere la tab privacy
                            </p>
                        </li>
                        <li>
                            <p>
                                muovere lo slider per scegliere il livello di privacy desiderato
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccando su avanzate si accede a ulteriori opzioni
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>Google Chrome</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                cliccare su strumenti
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su impostazioni
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su avanzate
                            </p>
                        </li>
                        <li>
                            <p>
                                scorrere fino a privacy e poi cliccare su impostazioni contenuti,
                                scegliere le impostazioni preferite
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>Mozilla Firefox</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                cliccare su menù
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su preferenze
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su privacy
                            </p>
                        </li>
                        <li>
                            <p>
                                scegliere le impostazioni preferite relativamente a cookie e
                                tracciamento
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>Apple Safari</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                cliccare su preferenze
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su privacy
                            </p>
                        </li>
                        <li>
                            <p>
                                scegliere le impostazioni preferite per i cookie
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                        <strong>Opera</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                cliccare su preferenze
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su avanzate
                            </p>
                        </li>
                        <li>
                            <p>
                                cliccare su cookie, scegliere le impostazioni preferite
                            </p>
                        </li>
                    </ol>

                </Container>
            </Box>
        </>
    );
}

export default Privacy;