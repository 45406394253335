import './App.css';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import API from '../../components/API/API';
import AppRoutes from '../../components/AppRoutes/AppRoutes';
import AdminRoutes from '../../components/Admin/AdminRoutes/AdminRoutes';
import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute';

function App() {
    return (
        <React.StrictMode>
            <Router>            
                <API>                    
                    <Switch>
                        <ProtectedRoute path='/admin' component={AdminRoutes} />
                        <Route path='/' component={AppRoutes} />               
                    </Switch>                    
                </API>            
            </Router>
        </React.StrictMode>
    );
}

export default App;
