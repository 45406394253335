import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Calendar from '../Calendar/Calendar';

import { useStyles } from './DashboardMainStyles.js'

function DashboardMain({ userInfo, company, appointmentsList }) {

    const classes = useStyles();

    return(
        <>
        <Box>
            <Container maxWidth="xl" className={classes.dashboardMainWrapper}>
            {(userInfo.groups && userInfo.groups.length === 0) &&
                <Grid container mb={2}>
                    <Grid item xs={12} sm={12} md={10}>                            
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" href={'/admin/elenco-appuntamenti/crea/'} className={classes.manageAppointment}>+ Aggiungi</Button>
                    </Grid>
                </Grid>
            }                    
                <Calendar />
            </Container>
        </Box>
        </>
    );
}

export default DashboardMain;