import React from 'react';
import PageHeader from '../../views/PageHeader/PageHeader';
import Doctors from '../Doctors/Doctors';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useStyles } from './SpecialistsStyles.js'

function Specialists({ listDepartments, listDoctors }) {

    const classes = useStyles();

    const pageInfo = [
        {
            title: 'Specialisti',
            subtitle: 'Si effettuano visite mediche in numerose branche specialistiche',
        },
    ];
    
    return (
        <>
            <PageHeader pageInfo={pageInfo} />
            <Box>
                <Container maxWidth="xl" className={classes.doctorsWrapper}>
                    <Doctors listDoctors={listDoctors} listDepartments={listDepartments} />
                </Container>
            </Box>
        </>
    );
}

export default Specialists;