import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appBarWrapper: {
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    appBar: {
        backgroundColor: '#3c70fa !important',
    },
    link: {
        color: '#ffffff !important',
        fontSize: '1.1rem',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: '700',
        margin: '10px 20px !important',
        [theme.breakpoints.down('lg')]: {
            margin: '5px 10px !important',
        }
    },
    socialAppBar: {
        backgroundColor: '#3c70fa',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    socialLink: {
        marginTop: '7px !important',
        marginRight: '15px !important',
        color: '#ffffff !important',
    },
    contactLink: {
        fontSize: '.875rem !important',
        color: '#ffffff !important',
        margin: '5px 10px !important',
    },
    mobileSiteWrapper: {
        display: 'none',
        fontFamily: '"Poppins", sans-serif',
        [theme.breakpoints.down('md')]: {
           display: 'block',
        },
    },
    mobileSiteImg: {
        verticalAlign: 'middle',
        marginLeft: '20px',
    },
    mobileSiteTitle: {
        fontFamily: '"Poppins", sans-serif',
        fontWeight: '700',
        marginLeft: '10px',
        color: '#fff',
    },
    loginButton: {
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
        '&:hover': {
            backgroundColor: '#fff',
            color: '#475360',
            border: '1px solid rgb(255 255 255)',
        },
    },
    dashboardButton: {
        color: '#475360',
        marginRight: '5px',
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    loggedMessage: {
        fontFamily: '"Poppins", sans-serif',
        marginRight: '5px',
        fontSize: '.875rem',
        [theme.breakpoints.down('md')]: {
           display: 'none',
        },
    },
    siteNameTop: {
        color: '#fff',
        fontFamily: '"Poppins", sans-serif',
        verticalAlign: 'text-bottom',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
}));

export { useStyles };
